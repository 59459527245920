import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
import formsRoutes from "./views/forms/formsRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import widgetsRoute from "./views/widgets/widgetsRoute";
import chartsRoute from "./views/charts/chartsRoute";
import dataTableRoute from "./views/dataTable/dataTableRoute";
import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
import pagesRoutes from "./views/pages/pagesRoutes";
import iconsRoutes from "./views/icons/iconsRoutes";
import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import chatRoutes from "./views/app/chat/chatRoutes";
import calendarRoutes from "./views/app/calendar/calendarRoutes";
import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
import contactRoutes from "./views/app/contact/contactRoutes";
 
import CategoryList from "../screen/CategoryList";
import InsertCategory from "../screen/InsertCategory";
import InsertUpdatePost from "../screen/InsertUpdatePost";
import PostList from "screen/PostList";
import HomePage from "screen/HomePage";
import ImageRepository from "screen/ImageRepository";
import CategorySeo from "screen/CategorySeo";
import AvatarList from "screen/AvatarList";
import PostOperations from "screen/PostOperations";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/signin" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
     
      {
        path: "/haber/haberEkle/:haberId/:mode",
        component:InsertUpdatePost 
      },
      {
        path: "/kategori/list",
        component:  CategoryList
      },
      {
        path: "/kategori/kategoriEkle/:categoryId",
        component: InsertCategory
      },
      {
        path: "/haber/haberlistesi",
        component: PostList
      },
      {
        path: "/haber/haberhareketleri",
        component: PostOperations
      },
      {
        path: "/anasayfa",
        component: HomePage
      },
      {
        path: "/",
        component: HomePage
      },
      {
        path : "/fotograflar",
        component : ImageRepository
      },
      {
        path : "/AvatarList",
        component : AvatarList
      },      
      {
        path : "/kategori/list",
        component : CategoryList
      },
      {
        path : "/seoListesi",
        component : CategorySeo
      },
    ]
  }
];

export default routes;
