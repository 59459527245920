import React, { useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SimpleCard } from "@gull";
import globalTypes from "helpers/globalTypes";
import network from "networking/network";
import { Button, Modal } from "react-bootstrap";
import localStorageService from "app/services/localStorageService";
import Swal from "sweetalert2";
import { Loading } from "@gull";
import f7WebNetwork from "networking/f7WebNetwork";
import helperFunc from "helpers/helperFunc";

const iconTypeList = [
    {
        key: "antdesign",
        value: "antdesign"
    },
    {
        key: "entypo",
        value: "entypo"
    },
    {
        key: "evilicon",
        value: "evilicon"
    },
    {
        key: "feather",
        value: "feather"
    },
    {
        key: "font-awesome",
        value: "font-awesome"
    },
    {
        key: "font-awesome-5",
        value: "font-awesome-5"
    },
    {
        key: "fontisto",
        value: "fontisto"
    },
    {
        key: "foundation",
        value: "foundation"
    },
    {
        key: "ionicon",
        value: "ionicon"
    },
    {
        key: "material",
        value: "material"
    },
    {
        key: "material-community",
        value: "material-community"
    },
    {
        key: "octicon",
        value: "octicon"
    },
    {
        key: "simple-line-icon",
        value: "simple-line-icon"
    },
    {
        key: "zocial",
        value: "zocial"
    },
]

const SocialAddressList = () => {

    const isInsertSocial = useRef(false);
    const [socialAddress, setSocialAddress] = useState([]);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [insertUpdateSocialModalVisible, setInsertUpdateSocialModalVisible] = useState(false);
    const [userInfo, setUserInfo] = useState({
        UserId: "",
        UserName: "",
        NameSurname: "",
        UserEmail: "",
        UserImage: "",
        UserType: -1
    });
    const [socialInformation, setSocialInformation] = useState({
        name: "",
        icon: "",
        webicon: "",
        iconType: "",
        address: ""
    });

    useEffect(() => {

        selectUserInfo();

        selectSocialAddress();
    }, []);

    const selectUserInfo = () => {

        var user = helperFunc.getUserInformation();

        setUserInfo(user);

    }

    function selectSocialAddress() {


        network.get("ProjectImages/news/socialAddress.json?d=" + new Date(), null, true).then((result) => {


            setSocialAddress(result.data);
        });
    }


    const editSocialAddress = (row) => {

        isInsertSocial.current = false;
        setSocialInformation(row);
        setInsertUpdateSocialModalVisible(true);
    }
    const insertSocialAddress = (row) => {

        isInsertSocial.current = true;

        setSocialInformation({
            name: "",
            icon: "",
            webicon: "",
            iconType: "",
            address: ""
        });
        setInsertUpdateSocialModalVisible(true);
    }

    const postForm = () => {

        if (socialInformation.address.length == 0 || socialInformation.icon.length == 0 ||
            socialInformation.iconType.length == 0 || socialInformation.name.length == 0 ||
            socialInformation.webicon.length == 0) {

            alert("Lütfen tüm alanları doldurunuz.");
        } else {

            var method = "updateSocialAddress";
            if (isInsertSocial.current == true) {
                method = "insertSocialAddress";
            }

            setLoadingVisible(true);

            var params = {
                name: socialInformation.name,
                address: socialInformation.address,
                icon: socialInformation.icon,
                iconType: socialInformation.iconType,
                webicon: socialInformation.webicon,
                UserId: userInfo.UserId
            };

            f7WebNetwork.post("/blogpostadmin/" + method + "/", params)
                .then(result => {
                    setLoadingVisible(false);
                    var data = result.data;
                    var messsage = "Sosyal medya başarılı bir şekilde güncellendi.";
                    if (isInsertSocial.current == true) {
                        messsage = "Sosyal medya başarılı bir şekilde eklendi.";
                    }
                    if (data.isSuccess) {
                        Swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Ok",
                            position: "top-end",
                            icon: "success",
                            text: messsage,
                            allowOutsideClick: true,
                            timer: 3000
                        });

                        setInsertUpdateSocialModalVisible(false);
                        selectSocialAddress();
                        // selectCategoryList();
                    } else {
                        setLoadingVisible(false);
                        alert(data.errorMessage);
                    }
                })

        }
    }


    const deleteSocialAddress = (row) => {

        if (window.confirm("Sosyal Medya adresi kalıcı olarak silinecek. Devam edilsin mi?")) {
            setLoadingVisible(true);

            var params = {
                name: row.name,
                UserId: userInfo.UserId
            };
            f7WebNetwork.post("/blogpostadmin/DeleteSocialAddress/", params)
                .then(result => {
                    setLoadingVisible(false);
                    var data = result.data;

                    if (data.isSuccess) {
                        Swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Ok",
                            position: "top-end",
                            icon: "success",
                            text: "Sosyal Medya silindi..",
                            allowOutsideClick: true,
                            timer: 3000
                        });

                        selectSocialAddress();
                        // selectCategoryList();
                    } else {
                        setLoadingVisible(false);
                        alert(data.errorMessage);
                    }
                })
        }

    }

    const buttonFormatter = (cell, row) => {

        return (
            <>

                <Button variant="danger" style={{marginRight:15}} onClick={() => { deleteSocialAddress(row) }}> <i className="i-Delete-File"> </i>  Sil</Button>
                <Button variant="primary" onClick={() => { editSocialAddress(row) }}> <i className="i-Edit"> </i>  Düzenle</Button>
            </>
        )
    }

    const sortableColumn = [
        {
            dataField: "name",
            text: "Sosyal Medya Adı",
            sort: true
        },
        {
            dataField: "icon",
            text: "Mobil İcon Adı",
            sort: true
        },
        {
            dataField: "webicon",
            text: "Web İcon Adı",
            sort: true
        },
        {
            dataField: "iconType",
            text: "Mobil İcon Tipi",
            sort: true
        },
        {
            dataField: "address",
            text: "İnternet Adresi ",
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            csvExport: false,
            formatter: buttonFormatter,
            headerStyle: {
                color: 'white'
            }
        }
    ];
    const paginationOpt = globalTypes.paginationOptions;
    paginationOpt.totalSize = socialAddress.length;
    const getLoadingPanel = () => {

        if (loadingVisible) {

            return (
                <Loading ></Loading>
            );
        } else {
            return null;
        }
    }

    const getCategoryItem = () => {

        return iconTypeList.map((item, index) => {


            if (item.key == socialInformation.iconType) {

                return (
                    <option selected value={item.key}>{item.value}</option>
                );
            } else {
                return (
                    <option value={item.key}>{item.value}</option>
                );
            }

        }
        );
    }
    const getPostCategorySelectView = () => {


        return (
            <>
                <select className="form-control" id="sel1" onChange={(ev) => {

                    setSocialInformation({
                        ...socialInformation,
                        iconType: ev.target.value,
                    })
                }}>
                    <option selected disabled>İcon Tipi Seç</option>
                    {getCategoryItem()}
                </select>

            </>
        );
    }


    return (

        <div>
            <SimpleCard title="Sosyal Medya Adresleri">
                {getLoadingPanel()}

                <Button onClick={() => {
                    insertSocialAddress()
                }} className="btn-icon m-1 text-capitalize" style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "row", marginRight: 15 }} variant="outline-primary">

                    <span className="ul-btn__icon">
                        <i className="i-Add"></i>
                    </span>
                    <span className="ul-btn__text">Sosyal Adres Ekle</span>

                </Button>

                <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={socialAddress}
                    columns={sortableColumn}
                    pagination={paginationFactory(paginationOpt)}
                    noDataIndication={"Herhangi bir Yorum bulunmamaktadır."}

                />
            </SimpleCard>



            <Modal show={insertUpdateSocialModalVisible} onHide={() => { setInsertUpdateSocialModalVisible(false) }} size="xl" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Sosyal Medya Ekle/Güncelle</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    <form>
                        <div className="form-group row">
                            <label
                                htmlFor="inputEmail3"
                                className="col-sm-2 col-form-label"
                            >
                                Sosyal Medya
                               </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputEmail3"
                                    placeholder=""
                                    value={socialInformation.name}
                                    onChange={(ev) => {
                                        setSocialInformation({
                                            ...socialInformation,
                                            name: ev.target.value,
                                        })
                                    }}
                                />
                                <span className="text-info">İsim sabit kalmalı değişmesin</span>

                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="inputPassword3"
                                className="col-sm-2 col-form-label"
                            >
                                Mobil İcon Tipi
                               </label>
                            <div className="col-sm-10">
                                {getPostCategorySelectView()}
                                <span className="text-info">
                                <a
                                style={{color:"blue"}}
                                href="https://oblador.github.io/react-native-vector-icons/" target="_blank">
                                    Bu Site
                                </a> üzerinden Mobil için İcon tipi ve adını alabilirsiniz
                            </span>
                            </div>
                        
                            
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="inputPassword3"
                                className="col-sm-2 col-form-label"
                            >
                                Mobil İcon Adı
                               </label>
                            <div className="col-sm-10">

                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputPassword3"
                                    placeholder=""
                                    value={socialInformation.icon}
                                    onChange={(ev) => {
                                        setSocialInformation({
                                            ...socialInformation,
                                            icon: ev.target.value,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="inputPassword3"
                                className="col-sm-2 col-form-label"
                            >
                                Web İcon Adı
                               </label>
                            <div className="col-sm-10">

                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputPassword3"
                                    placeholder=""
                                    value={socialInformation.webicon}
                                    onChange={(ev) => {
                                        setSocialInformation({
                                            ...socialInformation,
                                            webicon: ev.target.value,
                                        })
                                    }}
                                />
                                      <span className="text-info">
                                <a
                                style={{color:"blue"}}
                                href="https://fontawesome.com/v5.15/icons?d=gallery&p=2" target="_blank">
                                    Bu Site
                                </a> üzerinden Web için İcon adını alabilirsiniz. <b>fa- den sonra gelen kısım İcon adıdır.</b>
                            </span>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label
                                htmlFor="inputPassword3"
                                className="col-sm-2 col-form-label"
                            >
                                Sosyal Medya Adresi
                               </label>
                            <div className="col-sm-10">

                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputPassword3"
                                    placeholder=""
                                    value={socialInformation.address}
                                    onChange={(ev) => {
                                        setSocialInformation({
                                            ...socialInformation,
                                            address: ev.target.value,
                                        })
                                    }}
                                />
                            </div>
                        </div>


                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setInsertUpdateSocialModalVisible(false) }}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={() => { postForm() }}>
                        Kaydet
                     </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}
export default SocialAddressList;