import network from "networking/network";
import React, { useState, Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import { Loading } from "@gull";
import Swal from "sweetalert2";
import globalTypes from "helpers/globalTypes";
import f7WebNetwork from "networking/f7WebNetwork";

const NotificationSend = React.forwardRef(({ notificationType, UserId, changePostNotificationStatusFromLocal }, ref) => {

    const [notificationHeader, setNotificationHeader] = useState("");
    const [notificationContent, setNotificationContent] = useState("");
    const [notificationImage, setNotificationImage] = useState("");
    const [notificationId, setNotificationId] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [lessonGroupId, setLessonGroupId] = useState(-1);


    React.useImperativeHandle(ref, () => ({
        openModal(notificationId, defaultHeader, defaultContent, imageUrl, temlessonGroupId) {
            setNotificationHeader("");
            setNotificationContent(defaultHeader);
            setNotificationImage(imageUrl);
            setNotificationId(notificationId);
            setModalVisible(true);
            setLessonGroupId(temlessonGroupId);
        },
    }));



    const handleClose = () => {
        setModalVisible(false);
    };

    const updatePasswordClick = () => {

        if (notificationContent.trim().length == 0) {

            alert("Bildirim içeriği dolu olmalıdır...");
            return;
        } else {
            setLoadingVisible(true);


            var params = {
                newId: notificationId,
                userId: UserId,
                notificationHeader: notificationHeader,
                notificationContent: (notificationContent.replace("'", "")),
                imageUrl: notificationImage
            }
            // network.get("blogpostadmin/sendPostNotification", params)
            f7WebNetwork.post("/adminblogpost/sendPostNotification", params)
                .then(result => {


                    setLoadingVisible(false);

                    if (result.isSuccess) {
                        var data = result.data;
                        if (data.isSuccess) {
                            Swal.fire({
                                title: "Başarılı",
                                confirmButtonText: "Ok",
                                position: "top-end",
                                icon: "success",
                                text: "Bildirim başarılı bir şekilde gönderildi..",
                                allowOutsideClick: true,
                                timer: 3000
                            });

                            setModalVisible(false);
                            changePostNotificationStatusFromLocal(notificationId, true);


                        } else {
                            alert(data.errorMessage);
                        }
                    } else {
                        alert(result.errorMessage);
                    }
                });

            //string title, string content, string imageUrl, int lessonGroup, int newsId
            if (lessonGroupId > 0) {
                var bsvParams = {
                    newsId: notificationId,
                    title: notificationHeader,
                    content: notificationContent,
                    imageUrl: notificationImage,
                    lessonGroup: lessonGroupId
                }
                network.getFromUrl(globalTypes.bisorumvarService + "/notification/sendNewsNotification", bsvParams)
                    .then(result => {


                        if (result.isSuccess) {

                        } else {
                            alert("Bi sorum var hatasu " + result.errorMessage);
                        }
                    });
            }

        }

    }

    const getLoadingPanel = () => {

        if (loadingVisible) {

            return (
                <Loading ></Loading>
            );
        } else {
            return null;
        }
    }


    return (
        <Fragment>

            {getLoadingPanel()}
            <Modal scrollable show={modalVisible} size="lg" >
                <Modal.Header closeButton={false} >
                    <Modal.Title>Bildirim Gönder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 form-group mb-3">
                        <label htmlFor="setNotificationHeader">Bildirim Başlığı</label>
                        <input
                            id="setNotificationHeader"
                            name="setNotificationHeader"
                            className="form-control"
                            placeholder="Bildirim Başlığı"
                            type="text"
                            onChange={(ev) => {
                                setNotificationHeader(ev.target.value);
                            }}
                            value={notificationHeader}
                        />

                    </div>
                    <div className="col-md-12 form-group mb-3">
                        <label htmlFor="setNotificationContent">Bildirim Yazısı</label>
                        <textarea
                            rows={4}
                            id="setNotificationContent"
                            name="setNotificationContent"
                            className="form-control"
                            placeholder="Bildirim Yazısı"
                            type="text"
                            onChange={(ev) => {
                                setNotificationContent(ev.target.value);
                            }}
                            value={notificationContent}
                        />

                    </div>
                    <div className="col-md-12 form-group mb-3" >
                        <label htmlFor="setNotificationImage">Bildirim Fotoğrafı</label>
                        <input
                            id="setNotificationImage"
                            name="setNotificationImage"
                            className="form-control"
                            placeholder="Bildirim Fotoğrafı"
                            type="text"
                            onChange={(ev) => {
                                setNotificationImage(ev.target.value);
                            }}
                            value={notificationImage}
                        />
                        <div style={{ textAlign: "center" }}>
                            <img style={{ width: 180, height: 120, marginTop: 15, }} src={notificationImage}></img>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        İptal
                    </Button>
                    <Button variant="primary" onClick={updatePasswordClick}>
                        Gönder
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>
    );
})

export default NotificationSend;