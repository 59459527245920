import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SimpleCard } from "@gull";
import globalTypes from "helpers/globalTypes"; 
import { Button, Modal } from "react-bootstrap";
import localStorageService from "app/services/localStorageService";
import Swal from "sweetalert2";
import { Loading } from "@gull";
import f7WebNetwork from "networking/f7WebNetwork";
import helperFunc from "helpers/helperFunc";


const CategorySeo = () => {

    const [userInfo, setUserInfo] = useState({
        UserId: "",
        UserName: "",
        NameSurname: "",
        UserEmail: "",
        UserImage: "",
        UserType: -1
    });

    const [categorySeoList, setCategorySeoList] = useState([]);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [seoInsertUpdateModalVisible, setSeoInsertUpdateModalVisible] = useState(false);
    const history = useHistory();
    const [updatedCategorySeo, setUpdatedCategorySeo] = useState({
        CategoryId: -1,
        Keywords: "",
        Title: "",
        Description: "",
        ImageUrl: "",
        ImageWıdth: 0,
        ImageHeight: 0,
        cateforyName : ""
    });

    useEffect(() => {
        setLoadingVisible(true);

        selectCategorySeoList();
        selectUserInfo();
    }, []);
    const selectCategorySeoList = () => {


        f7WebNetwork.get("/admin/selectCategorySeoList")
            .then(result => {
                setLoadingVisible(false);
                var data = result.data;

             
                setCategorySeoList(data || []);

            })

    }
    const selectUserInfo = () => {

        var user = helperFunc.getUserInformation();

        setUserInfo(user);

    }

    const saveSeoInformationPreview = () => {

        if (((updatedCategorySeo.Description || "").trim().length == 0)) {
            alert("Lütfen seo açıklaması alanını doldurunuz.");
            return;
        }
        saveSeoInformation();
    }

    const saveSeoInformation = () => {

        setLoadingVisible(true);


        // var params = updatedCategorySeo;
        /**
         *         public int CategoryId { get; set; }
        public string Keywords { get; set; }
        public string Description { get; set; }
        public string ImageUrl { get; set; }
        public int ImageWıdth { get; set; }
        public int ImageHeight { get; set; }
         */
        var params = {
            CategoryId : updatedCategorySeo.CategoryId,
            Keywords : updatedCategorySeo.Keywords ||"",
            Title : updatedCategorySeo.Title ||"",
            Description : updatedCategorySeo.Description,
            ImageUrl : updatedCategorySeo.ImageUrl || "",
            ImageWıdth : updatedCategorySeo.ImageWıdth || 0,
            ImageHeight : updatedCategorySeo.ImageHeight || 0,
        }
        f7WebNetwork.post("/admin/updateCategoryPageSeo/", params)
            .then(result => {
                setLoadingVisible(false);
                if (result.isSuccess) {
                    var data = result.data;

                    if (data.isSuccess) {
                        setSeoInsertUpdateModalVisible(false);
                        Swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Ok",
                            position: "top-end",
                            icon: "success",
                            text: "İşlem başarılı bir şekilde gerçekleşti",
                        });
    
                        selectCategorySeoList();
                    } else {
                        alert(data.errorMessage);
                    }
                }else {
                    alert(result.errorMessage);
                }
            


             

            })
    }

    const buttonFormatter = (cell, row) => {


        return (
            <>
                <Button variant="outline-primary" style={{ marginRight: 15 }} onClick={() => {
                    setUpdatedCategorySeo({
                        CategoryId: row.CATEGORYID,
                        Description: row.DESCRIPTION,
                        ImageHeight: row.IMAGEHEIGHT,
                        ImageUrl: row.IMAGEURL,
                        ImageWıdth: row.IMAGEWIDTH,
                        cateforyName : row.NAME,
                        Title: row.TITLE
                    });
                    setSeoInsertUpdateModalVisible(true);
                }}>
                    <i className="i-Edit"> </i>
                    Düzenle
                </Button>

                {/* {getDeleteButton(row)} */}
            </>
        )
    }


    /**
        *     SELECT CAT.TERMID AS CATEGORYID, SEO.CATEGORYSEOID, SEO.[DESCRIPTION], SEO.IMAGEHEIGHT, SEO.IMAGEURL, SEO.IMAGEWIDTH, SEO.KEYWORDS
   from TBLCATEGORYSEO seo
       RIGHT JOIN TBLPOSTCATEGORY CAT
       ON CAT.TERMID = seo.CATEGORYID
        */

   




    const getLoadingPanel = () => {

        if (loadingVisible) {

            return (
                <Loading ></Loading>
            );
        } else {
            return null;
        }
    }

    const getSeoInsertUpdateModal = () => {


        return (
            <Modal show={seoInsertUpdateModalVisible} onHide={() => { setSeoInsertUpdateModalVisible(false) }} size="xl" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Seo Bilgilerini Güncelle</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <div className="form-group row">
                        <label
                            htmlFor="inputEmail3"
                            className="col-sm-2 col-form-label"
                        >
                            Kategori Adı
                        </label>
                        <div className="col-sm-10">
                            <label>
                                {updatedCategorySeo.cateforyName}
                            </label>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label
                            htmlFor="inputEmail3"
                            className="col-sm-2 col-form-label"
                        >
                            Başlık
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control"
                                id="inputEmail3"
                                placeholder=""
                                value={updatedCategorySeo.Title}
                                onChange={(ev) => {

                                    setUpdatedCategorySeo({
                                        ...updatedCategorySeo,
                                        Title: ev.target.value,

                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label
                            htmlFor="inputEmail3"
                            className="col-sm-2 col-form-label"
                        >
                            Seo Açıklaması
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control"
                                id="inputEmail3"
                                placeholder=""
                                value={updatedCategorySeo.Description}
                                onChange={(ev) => {

                                    setUpdatedCategorySeo({
                                        ...updatedCategorySeo,
                                        Description: ev.target.value,

                                    })
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label
                            htmlFor="inputPassword3"
                            className="col-sm-2 col-form-label"
                        >
                            Anahtar kelimeler
                        </label>
                        <div className="col-sm-10">

                            <input
                                type="text"
                                className="form-control"
                                id="inputPassword3"
                                placeholder=""
                                value={updatedCategorySeo.Keywords}
                                onChange={(ev) => {

                                    setUpdatedCategorySeo({
                                        ...updatedCategorySeo,
                                        Keywords: ev.target.value,

                                    })
                                }}
                            /> 
                        </div>
                    </div>

                    <div className="form-group row">
                        <label
                            htmlFor="inputPassword3"
                            className="col-sm-2 col-form-label"
                        >
                            Fotoğraf Url
                        </label>
                        <div className="col-sm-10">

                            <input
                                type="text"
                                className="form-control"
                                id="inputPassword3"
                                placeholder=""
                                value={updatedCategorySeo.ImageUrl}
                                onChange={(ev) => {

                                    setUpdatedCategorySeo({
                                        ...updatedCategorySeo,
                                        ImageUrl: ev.target.value,

                                    })
                                }}
                            /> 
                        </div>
                    </div>
                    <div className="form-group row">
                        <label
                            htmlFor="inputPassword3"
                            className="col-sm-2 col-form-label"
                        >
                            Fotoğraf Yükseklik
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="number"
                                className="form-control"
                                id="inputPassword3"
                                placeholder=""
                                value={updatedCategorySeo.ImageHeight}
                                onChange={(ev) => {

                                    setUpdatedCategorySeo({
                                        ...updatedCategorySeo,
                                        ImageHeight: ev.target.value,

                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label
                            htmlFor="inputPassword3"
                            className="col-sm-2 col-form-label"
                        >
                            Fotoğraf Genişlik
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="number"
                                className="form-control"
                                id="inputPassword3"
                                placeholder=""
                                value={updatedCategorySeo.ImageWıdth}
                                onChange={(ev) => {

                                    setUpdatedCategorySeo({
                                        ...updatedCategorySeo,
                                        ImageWıdth: ev.target.value,

                                    })
                                }}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setSeoInsertUpdateModalVisible(false) }}>
                        İptal
                    </Button>
                    <Button variant="primary" onClick={() => { saveSeoInformationPreview() }}>
                        Güncelle
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const paginationOpt = globalTypes.paginationOptions;
    paginationOpt.totalSize = categorySeoList.length;
    const sortableColumn = [
        {
            dataField: "CATEGORYID",
            text: "Kategori Id",
            sort: true
        },
        {
            dataField: "NAME",
            text: "Kategori Adı",
            sort: true
        },
        {
            dataField: "TITLE",
            text: "Başlık",
            sort: true
        },
        {
            dataField: "DESCRIPTION",
            text: "Açıklama",
            sort: true
        },
        {
            dataField: "KEYWORDS",
            text: "Anahtar kelimeler",
            sort: true
        },
        {
            dataField: "IMAGEURL",
            text: "Fotoğraf Url",
            sort: true
        },
        {
            dataField: "IMAGEHEIGHT",
            text: "Fotoğraf Yükseklik",
            sort: true
        },
        {
            dataField: "IMAGEWIDTH",
            text: "Fotoğraf Genişlik",
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            csvExport: false,
            formatter: buttonFormatter,
            headerStyle: {
                color: 'white'
            }
        }
    ];

    return (
        <div>
            {getLoadingPanel()}
            {getSeoInsertUpdateModal()}

            <Button onClick={() => {
                history.push({
                    pathname: "/kategori/kategoriEkle/0"
                });
            }} className="btn-icon m-1 text-capitalize" style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "row", marginRight: 15 }} variant="outline-primary">

                <span className="ul-btn__icon">
                    <i className="i-Add"></i>
                </span>
                <span className="ul-btn__text">Kategori Ekle</span>

            </Button>


            <div>

                <SimpleCard title="Kategori Listesi">
                    <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={categorySeoList}
                        columns={sortableColumn}

                        // defaultSorted={this.defaultSorted}
                        pagination={paginationFactory(paginationOpt)}
                        
                        noDataIndication={"Kategori bulunmamaktadır."}

                    />
                </SimpleCard>
            </div>

        </div>
    );

}
export default CategorySeo;