import Axios from "axios";
import globalTypes from "../helpers/globalTypes";
import apiOperations from "./apiOperations";




const f7WebNetwork = {

    get: async function (method, obj, selectFromJson) {
        var queryString = "";
        if (obj) {
            queryString = "/?" + apiOperations.objToQueryString(obj);
        }

    
     
        var serviceAddress = await apiOperations.GetWebAddress();
        if (selectFromJson) {
            serviceAddress = globalTypes.serviceUrl;
        }
        const url = serviceAddress + method + queryString;

        console.log(url);
 
        try {
            return apiOperations.apiSuccess(await (await Axios.get(url)).data);

        } catch (error) {

            return apiOperations.apiError(error);
        }
    },

    getFromUrl: async function (method, obj) {
        var queryString = "";
        if (obj) {
            queryString = "/?" + apiOperations.objToQueryString(obj);
        }

        const url =  method + queryString;
 
 
        try {
            return apiOperations.apiSuccess(await (await Axios.get(url)).data);

        } catch (error) {

            return apiOperations.apiError(error);
        }
    },

    post: async function (method, obj) {
         const serviceAddress = await apiOperations.GetWebAddress();
        const url = serviceAddress + method;

        try { 
            var data = apiOperations.apiSuccess(await (await Axios.post(url, obj,  {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8" 
                }
             })).data);
             return data;

        } catch (error) {
 
            return apiOperations.apiError(error);
        }
    },

}

export default f7WebNetwork;