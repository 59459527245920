
import { SimpleCard } from "@gull";
import f7WebNetwork from "networking/f7WebNetwork";
import network from "networking/network";
import React, { useEffect, useState } from "react"
import { WithContext as ReactTags } from "react-tag-input";




const PostInsertSeoArea = ({ selectedTagList, setSelectedTagList, postKeywords, setPostKeywords, seoDescription, setSeoDescription }) => {

    const [tagList, setTagList] = useState([]);
    // const [selectedTagList, setSelectedTagList] = useState([]);


    useEffect(() => {
        selectTagList();
    }, []);

    const selectTagList = () => {


        f7WebNetwork.get("/admin/selectPostTagList")
            .then(result => {

                var data = (result.data) || [];

        
                setTagList(data);

            })
    }


    const handleDelete = i => {


        setSelectedTagList(
            selectedTagList.filter((tag, index) => index !== i)
        );
    };

    const handleAddition = tag => {

        var newList = [...selectedTagList, tag];
        setSelectedTagList(newList);
    };

    const handleDrag = (tag, currPos, newPos) => {
        if (!newPos) return;

        let tags = [...selectedTagList];

        tags.splice(currPos, 1);
        tags.splice(newPos, 0, tag);

        setSelectedTagList(tags);
    };



    //   const { tags, suggestions } = state;
    return (
        <div className={""}>

            {/* <SimpleCard className={"col-md-4"} style={{
                borderWidth: 1,
                borderColor: "#ddd",
                padding: 10,
                boxShadow: "5px 5px #c1c1c1",
                marginBottom: 15,
                borderStyle: "solid",

            }} title={"Tag Listesi"} > */}
            <label>
                Tag Listesi
            </label>
            <div style={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "gray"
            }} >
                <ReactTags
                    tags={selectedTagList}
                    suggestions={tagList}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    allowDragDrop={false}
                    delimiters={[188, 13]}
                    placeholder="Yeni Tag Ekle"
                    classNames={"form-control"}
                />
            </div>
            {/* </SimpleCard>

            <SimpleCard className={"col-md-4"}  style={{
                borderWidth: 1,
                borderColor: "#ddd",
                padding: 10,
                boxShadow: "5px 5px #c1c1c1",
                marginBottom: 15,
                borderStyle: "solid",

            }} title={"Anahtar kelimeler"} > */}


            <div className="mb-2"></div>
            <label>
                Anahtar Kelimeler
            </label>
            <textarea value={postKeywords} onChange={(val) => { setPostKeywords(val.target.value) }}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="2"
                placeholder="Anahtar kelimeler..."
            ></textarea>

            <span className="text-info">Her bir anahtar kelimenin arasına <b>,</b> ekleyin</span>
            {/* 
            </SimpleCard>

            <SimpleCard  className={"col-md-4"}  style={{
                borderWidth: 1,
                borderColor: "#ddd",
                padding: 10,
                boxShadow: "5px 5px #c1c1c1",
                marginBottom: 15,
                borderStyle: "solid",

            }} title={"Açıklama"} > */}


            <div className="mb-2"></div>
            <label>
                Seo Açıklaması
            </label>

            <textarea maxLength="500" value={seoDescription} onChange={(val) => { setSeoDescription(val.target.value) }}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="2"
                placeholder="Seo İçin Açıklama..."
            ></textarea>
            <span className="text-info">Haberin Özeti. Aramalarda faydalı oluyor.</span>


            {/* </SimpleCard> */}

        </div>
    );
}

export default PostInsertSeoArea;