import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { Modal, Button } from "react-bootstrap";
/*
 * Simple editor component that takes placeholder text as a prop
 */

const RichTextEditor = ({
  content,
  placeholder,
  handleContentChange,
  theme = "snow",
  modules = RichTextEditor.modules
}) => {

  const quillRef = useRef();
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  const onVideoAdd = (videoName) => {

    var selection =  quillRef.current.getEditor().getSelection();
    var  index = 0;
    if (selection) {
      index = selection.index;
    }
     
    // quillRef.current.getEditor().insertEmbed(index, "video",videoName);
    quillRef.current.getEditor().pasteHTML(0, videoName ,"")
    // quillRef.current.setSelection(cursorPosition + 1);
  }


  return (
    <>
      <QuillModalIframeVideo visible={videoModalVisible} setVisible={setVideoModalVisible}
        onVideoAdd={onVideoAdd}></QuillModalIframeVideo>
      <ReactQuill
        theme={theme}
        ref={quillRef}
        onChange={handleContentChange}
        value={content}
        modules={{
          toolbar: {
            container: [
              [{ font: [] }],
              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "code-block", "link"],

              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ align: [] }],

              ["image", "video"],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              ["clean"]
            ],
            // handlers: {
            //   'video': function (value) {
            //     setVideoModalVisible(true);
            //   }
            // }
          }
          ,
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: true
          },
        }}
        formats={RichTextEditor.formats}
        placeholder={placeholder}

        style={{
          height: 750,
          overflow: "auto"
        }}

      />
    </>
  );
};

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RichTextEditor.modules = {
  toolbar: {
    container: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block", "link"],

      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],

      ["image", "video"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      ["clean"]
    ],
    handlers: {
      'video': function (value) {
        alert('notice');
      }
    }
  }
  ,
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
RichTextEditor.formats = [
  "align",
  "background",
  "bold",
  "blockquote",
  "bullet",
  "color",
  "code",
  "code-block",
  "clean",
  "direction",
  "font",
  "header",
  "italic",
  "indent",
  "image",
  "list",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "video"
];

/*
 * PropType validation
 */
RichTextEditor.propTypes = {
  placeholder: PropTypes.string
};

export default RichTextEditor;





const QuillModalIframeVideo = ({ onVideoAdd, visible, setVisible }) => {

  const [iframeString, setIframeString] = useState("");


  return (
    <Modal show={visible} onHide={() => { setVisible(false) }} centered>
      <Modal.Header closeButton>
        <Modal.Title>Video Kaynak Kodu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea maxLength="500" value={iframeString} onChange={(val) => {

          setIframeString(val.target.value);

        }}
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="2"
          placeholder="Haber Kısa Açıklamasını Giriniz..."
        ></textarea>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { setVisible(false) }}>
          Kapat
        </Button>
        <Button variant="primary" onClick={() => {

          if (iframeString.length > 0) {
            onVideoAdd(iframeString);
          }

        }}>
          Ekle
        </Button>
      </Modal.Footer>
    </Modal>
  );
}