import  * as actionTypes from "../actions/actionTypes";


const initialState = {
  success: true,
  loading: false,
  error: ""
};

const LoginReducer = function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case actionTypes.RESET_PASSWORD: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case actionTypes.LOGIN_ERROR: {
      return {
        success: false,
        loading: false,
        error: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default LoginReducer;
