import React from "react";

const styles = {
  position: "fixed",
  left: 0,
  right: 0,
  top: "calc(50% - 20px)",
  margin: "auto",
  height: "40px",
  width: "40px",
  zIndex: 324324324,

 
};
const overlay = { position: "fixed",
display: "block",
width: "100%",
height: "100%",
top: 0,
left: 0,
right: 0,
bottom: 0,
backgroundColor: "rgba(0, 0, 0, 0.5)",
zIndex: 15000,}

const Loading = () => {
  return (
    <div style={overlay}>
      <div style={styles}>
        <div className="spinner spinner-bubble spinner-bubble-primary"></div>
      </div>
    </div>
  );
};

export default Loading;
