import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SimpleCard } from "@gull";
import globalTypes from "helpers/globalTypes";
import network from "networking/network";
import { Button } from "react-bootstrap";
import localStorageService from "app/services/localStorageService";
import Swal from "sweetalert2";
import { Loading } from "@gull";
import SocialAddressList from "Component/SocialAddressList";
import MUIDataTable from "mui-datatables";
import f7WebNetwork from "networking/f7WebNetwork";
import helperFunc from "helpers/helperFunc";




const HomePage = () => {

    const [userInfo, setUserInfo] = useState({
        UserId: "",
        UserName: "",
        NameSurname: "",
        UserEmail: "",
        UserImage: "",
        UserType: -1
    });
    const [loadingVisible, setLoadingVisible] = useState(false);

    const [commentList, setCommentList] = useState([]);
    const [selectedComments, setSelectedComments] = useState([]);

    useEffect(() => {

        selectUserInfo(); 
 
    }, []);

    useEffect(() => {

        if (userInfo.UserId?.length > 0) {
            selectCommentList();
        }

    }, [userInfo])
    const selectUserInfo = () => {

        var user = helperFunc.getUserInformation();

        setUserInfo(user);
    }
    const onSelectComment=(row, isSelect)=>{

        var selected = JSON.parse(JSON.stringify(selectedComments));
        const {COMMENTID} = row;
        if (isSelect) {
            selected.push(COMMENTID);
        } else {
            var index = selected.indexOf(COMMENTID);
            if (index !=-1) {
                selected.splice(index, 1); 
            }
         
        }
        setSelectedComments(selected);
   
    }

    const selectCommentList = () => {

        var params = {
            userId: userInfo.UserId
        }

        f7WebNetwork.get("/admin/selectAllComment", params)
            .then(result => {
                var data = result.data;

              
                if (data) {
                    setCommentList(data);
                }
               

            })
    }
    const changeCommentStatus = (item, newStatus) => {


        var text = newStatus == true ? "Yorum Aktife alınacak. Devam edilsin mi?" : "Yorum Pasife alınacak. Devam edilsin mi?";
        if (window.confirm(text)) {

            setLoadingVisible(true);
            var params = {
                userId: userInfo.UserId,
                status: newStatus,
                commentId: item.COMMENTID
            }

            f7WebNetwork.get("/admin/changeCommentStatus", params)
                .then(result => {
                    setLoadingVisible(false);

                    var data = result.data;

                    if (data.isSuccess) {
                        Swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Ok",
                            position: "top-end",
                            icon: "success",
                            text: newStatus == true ? "Yorum Aktife alındı" : "Yorum Pasife alındı..",
                        });
                    
                        // var list = JSON.parse(JSON.stringify(commentList));
                        // var index = list.findIndex(w=>w.COMMENTID  == item.COMMENTID);
                        // list[index].COMMENTAPPROVED = newStatus;
                        // list[index].APPROVEDTEXT = newStatus ? "Onaylandı" : "Onaylanmadı";
                        // setCommentList(list);
                        selectCommentList();

                    } else {
                        alert(data.errorMessage);
                    }
                })
        }
    }
    
    const batchDelete =()=>{
        var text = "Seçilen yorumlar kalıcı olarak silinecek. Devam edilsin mi?";
        if (window.confirm(text)) {

            setLoadingVisible(true);
            var commentIds = selectedComments.join(",");

            var params = {
                userId: userInfo.UserId,
                commentIds: commentIds
            }

            f7WebNetwork.get("/admin/batchDeleteComment", params)
                .then(result => {
                    setLoadingVisible(false);

                    var data = result.data;

                    if (data.isSuccess) {
                        Swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Ok",
                            position: "top-end",
                            icon: "success",
                            text: "Yorum Silindi" 
                        }); 

                        selectCommentList();
                        setSelectedComments([]);

                    } else {
                        alert(data.errorMessage);
                    }
                })
        }
        
    }
    const batchMakeActive =()=>{
        var text = "Seçilen yorumlar aktif edilecek. Devam edilsin mi?";
        if (window.confirm(text)) {

            setLoadingVisible(true);
            var commentIds = selectedComments.join(",");

            var params = {
                userId: userInfo.UserId,
                commentIds: commentIds
            }

            f7WebNetwork.get("/admin/batchActiveComment", params)
                .then(result => {
                    setLoadingVisible(false);

                    var data = result.data;

                    if (result.isSuccess && data.isSuccess) {
                        Swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Ok",
                            position: "top-end",
                            icon: "success",
                            text: "Yorum Aktife Alındı" 
                        }); 

                        selectCommentList();
                        setSelectedComments([]);

                    } else {
                        alert(result.errorMessage);
                    }
                })
        }
    }

    const getDeleteButton = (row) => {



        if (row.COMMENTAPPROVED) {

            return (
                <Button variant="danger" onClick={() => { changeCommentStatus(row, false) }}> <i className="i-Delete-File"> </i>  Pasife Al</Button>
            );
        } else {
            return (
                <Button variant="primary" onClick={() => { changeCommentStatus(row, true) }}> <i className="i-Delete-File"> </i>  Aktife Al</Button>
            );
        }



    }

    const buttonFormatter = (cell, row) => {
        // const rowIndex = commentList.findIndex(w => w.COMMENTID == row2[0]);
        // const row = commentList[rowIndex];
        return (
            <>

                {getDeleteButton(row)}
            </>
        )
    } 

    const sortableColumn = [
        {
            dataField: "COMMENTID",
            text: "Id",
            sort: true
        },
        {
            dataField: "POSTTITLE",
            text: "Haber Başlığı",
            sort: true
        }, 
        {
            dataField: "INSERTDATETIME",
            text: "Ekleme Tarihi",
            sort: true
        },
        {
            dataField: "COMMENTCONTENT",
            text: "Yorum ",
            sort: true,
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    color: 'blue'
                  };
              }
        }, 
        {
            dataField: "COMMENTAPPROVED",
            text: "Onaylandı mı? ",
            sort: true,
            hidden: true
        },

        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            csvExport: false,
            formatter: buttonFormatter,
            headerStyle: {
                color: 'white'
            }
        }
    ];

    const getLoadingPanel = () => {

        if (loadingVisible) {

            return (
                <Loading ></Loading>
            );
        } else {
            return null;
        }
    }

    const GetSelectAction =()=>{

        if (selectedComments.length>0) {
            return(
                <div style={{ 
                }}>
                  <Button variant="danger" onClick={() => { batchDelete() }} className="mr-2"> <i className="i-Delete-File"> </i>  Toplu Sil</Button>
                  <Button variant="primary" onClick={() => { batchMakeActive() }}> <i className="i-Delete-File"> </i>  Toplu Aktife Al</Button>

                </div>
            );
        }else return null;
    }
   const paginationOpt = globalTypes.paginationOptions;
    paginationOpt.totalSize = commentList.length;
    return (

        <div>
            {getLoadingPanel()}

     
            <SimpleCard  title="Yorum Listesi" extra={<GetSelectAction />} >
                {/* {getSelectAction()  } */}
                <BootstrapTable
                    bootstrap4
                    keyField="COMMENTID"
                    data={commentList}
                    columns={sortableColumn}
                    selectRow={{
                        mode:"checkbox", 
                        onSelect:(row, isSelect, index)=>{
                           
                            onSelectComment(row, isSelect);
                        },
                        onSelectAll:(isSelect, rows)=>{
                          for (let i = 0; i < rows.length; i++) {
                              const element = rows[i];
                              onSelectComment(element, isSelect);
                          }
                        },
                        selected:selectedComments,
                    }}
                    // defaultSorted={this.defaultSorted}
                    pagination={paginationFactory(paginationOpt)}
                    noDataIndication={"Herhangi bir Yorum bulunmamaktadır."}

                />
                   {/* <MUIDataTable
                    title={"Haber Listesi"}
                    data={commentList}
                    columns={[
                        {
                            name: "COMMENTID",
                            label: "Yorum Id",
                            sort: true,
                        },
                        {
                            name: "POSTTITLE",
                            label: "Haber Başlığı",
                            sort: true,
                            search: true,
                            width: 250,
                            style: {
                                columnSpan: 3
                            },
                        }, 
                        {
                            name: "INSERTDATETIME",
                            label: "Ekleme Tarihi",
                            sort: true,
                            options:{
                                
                            }
                        },
                        {
                            name: "COMMENTCONTENT",
                            label: "Yorum",
                            sort: true
                        },
                        {
                            options: {
                                customBodyRender: (row, tableMeta) => buttonFormatter(null, tableMeta.rowData)
                            },
                            name: 'actions',
                            label: 'Actions',
                            isDummyField: true,
                            csvExport: false,
                            width: 200,
                            formatter: buttonFormatter,
                            headerStyle: {
                                color: 'white'
                            }
                        }
                    ]}
                    options={{
                        download: false,
                        print: false,
                        viewColumns: false,
                        filterType: "multiselect",
                        isRowSelectable: false,
                        selectableRowsHeader: true, 
                        rowsPerPage: 50,
                        selectableRows :"multiple",

                    }} 
                />  */}
            </SimpleCard>

            <div style={{marginTop:25}}></div>

            <SocialAddressList></SocialAddressList>
        </div>
    );
}

export default HomePage;



