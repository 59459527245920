import history from "@history.js";
import localStorageService from "app/services/localStorageService";
import f7WebNetwork from "networking/f7WebNetwork";
import network from "networking/network";
import jwtAuthService from "../../services/jwtAuthService";
import * as actionTypes from "./actionTypes"
import helperFunc from "helpers/helperFunc";


export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_USER_DATA,
      data: user
    });
  };
}

export function checkUser(isRouteMain){

 
 
    var userInfo =helperFunc.getUserInformation()
    var userID = 0;
    try {
    
      userID = userInfo.UserId;
    } catch (error) {
      
    }

    var params = {
      userId : (userID),  
    }
    f7WebNetwork.get("/adminuser/checkUser",params)
      .then(result => {
 
        var data = result.data;
        if (data == true) { 
          
          if (isRouteMain) {
            history.push({
              pathname: "/anasayfa"
            });  
          }
        } else {

          history.push({
            pathname: "/signin"
          });   
        }
   
      })
 
}


export function logoutUser() {
  return dispatch => {
    jwtAuthService.logout();

    history.push({
      pathname: "/signin"
    });

    dispatch({
      type: actionTypes.USER_LOGGED_OUT
    });
  };
}
