import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";

const RootReducer = combineReducers({
   LoginReducer,
    UserReducer,
   LayoutReducer,
    ScrumBoardReducer,
    NotificationReducer,
    EcommerceReducer
});

export default RootReducer;
