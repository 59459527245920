import globalTypes from "../helpers/globalTypes";


const apiOperations={


    GetServiceAddress: async function () {
   

        return globalTypes.getServiceFullUrl();
        // return this.serviceAddress + this.serviceName + this.svcPath ;
    },
    GetWebAddress: async function () {
   

        return globalTypes.webProjectUrl;
        // return this.serviceAddress + this.serviceName + this.svcPath ;
    },
    objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {

            keyValuePairs.push((key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    },
    apiSuccess(result =[]) {
        return ({
            isSuccess: true,
            data: result,
            errorMessage: ""
        });
    },
    apiError(errorMsg) {
        return ({
            isSuccess: false,
            errorMessage: errorMsg,
            data: null
        });
    },
    
}

export default apiOperations;