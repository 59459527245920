import network from "networking/network";
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router";

import { Loading } from "@gull";
import Swal from "sweetalert2";
import f7WebNetwork from "networking/f7WebNetwork";
const InsertCategory = () => {

  let { categoryId } = useParams();
  const history = useHistory();

  const [loadingVisible, setLoadingVisible] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState({
    TERMID: -1,
    NAME: "",
    SLUG: "",
    DISPLAYORDER: 0,
    ISMAINCATEGORY: false,
    PARENTID: -1
  });
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {

    if (categoryId) {

      selectCategoryInfo(categoryId);
    }

    selectCategoryList();
  }, [categoryId]);

  const selectCategoryInfo = (c_Id) => {
    setLoadingVisible(true);
    f7WebNetwork.get("/admin/selectSıngleCategory/" + c_Id)
      // f7WebNetwork.get("admin/selectSıngleCategory/"+ c_Id)
      .then(result => {

        var data = result.data;

        if (data && data.length > 0) {
    
          setCategoryInfo(data[0]);
        }

      })
  }

  const selectCategoryList = () => {

    f7WebNetwork.get("/admin/selectAllCategory") 
      .then(result => {
        setLoadingVisible(false);
        var data = result.data;


        if (data) {
          var catIndex= data.findIndex(w=>w.id == categoryId);
          if (catIndex != -1) {
            data.splice(catIndex, 1);
          }

          data.sort(function(a, b){
            if(a.NAME < b.NAME) { return -1; }
            if(a.NAME > b.NAME) { return 1; }
            return 0;
        })
          setCategoryList(data);
        }
      })
  }
  const postClick = () => {
    setLoadingVisible(true);

    f7WebNetwork.post("/admin/insertUpdateCategory/", categoryInfo)
      .then(result => {
        setLoadingVisible(false);
        var data = result.data;

        if (data.isSuccess) {
          Swal.fire({
            title: "Başarılı",
            confirmButtonText: "Ok",
            position: "top-end",
            icon: "success",
            text: "İşlem başarılı bir şekilde gerçekleşti",
          });

          history.push({
            pathname: "/kategori/list"
          });
        } else {
          alert(data.errorMessage);
        }

      })

  }


  const getLoadingPanel = () => {

    if (loadingVisible) {

      return (
        <Loading ></Loading>
      );
    } else {
      return null;
    }
  }

  /*
  DISPLAYORDER: 2
ICON: ""
ISMAINCATEGORY: true
NAME: "Kamu"
PARENTID: -1
SLUG: "kamu"
TERMID: 13
  */
  const getCategoryItem = () => {

 
    return categoryList.map((item, index) => {


      if (item.TERMID == categoryInfo.PARENTID) {
 
        return (
          <option selected value={item.TERMID}>{item.NAME}</option>
        );
      } else {
        return (
          <option value={item.TERMID}>{item.NAME}</option>
        );
      }

    }
    );
  }

  const getPostCategorySelectView = () => {


    return (

      <div className="form-group row">
        <label
          htmlFor="inputPassword3"
          className="col-sm-2 col-form-label"
        >
          Üst Kategorisi
        </label>
        <div className="col-sm-10">
          <select className="form-control " id="sel1" onChange={(ev) => {

            setCategoryInfo({
              ...categoryInfo,
              PARENTID: ev.target.value,
            })
          }}>
            <option selected  value={-1}>Kategori Seç</option>
            {getCategoryItem()}
          </select>
        </div>
      </div>

    );
  }


  return (
    <div>
      {getLoadingPanel()}

      <div className="card mb-5">
        <div className="card-body">
          <form>
            <div className="form-group row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-2 col-form-label"
              >
                Kategori Adı
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail3"
                  placeholder=""
                  value={categoryInfo.NAME}
                  onChange={(ev) => {

                    setCategoryInfo({
                      ...categoryInfo,
                      NAME: ev.target.value,

                    })
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="inputPassword3"
                className="col-sm-2 col-form-label"
              >
                Kategori Url
              </label>
              <div className="col-sm-10">

                <input
                  type="text"
                  className="form-control"
                  id="inputPassword3"
                  placeholder=""
                  value={categoryInfo.SLUG}
                  onChange={(ev) => {
                    setCategoryInfo({
                      ...categoryInfo,
                      SLUG: ev.target.value,

                    })
                  }}
                />
                <span className="text-info">Türkçe karakter ve boşluk olmasın. Boşluk yerine "-" Kullanılabilir</span>
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="inputPassword3"
                className="col-sm-2 col-form-label"
              >
                Görünüm Sırası
              </label>
              <div className="col-sm-10">
                <input
                  type="number"
                  className="form-control"
                  id="inputPassword3"
                  placeholder=""
                  value={categoryInfo.DISPLAYORDER}
                  onChange={(ev) => {
                    setCategoryInfo({
                      ...categoryInfo,
                      DISPLAYORDER: parseInt(ev.target.value),
                    })
                  }}
                />
              </div>
            </div>


            <div className="form-group row">
              <div className="col-sm-2">Ana Kategori Mi?</div>
              <div className="col-sm-10">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck1"
                    onChange={(ev) => {
                      setCategoryInfo({
                        ...categoryInfo,
                        ISMAINCATEGORY: ev.target.checked
                      });
                    }}
                    checked={categoryInfo.ISMAINCATEGORY}
                  />
                </div>
              </div>
            </div>

            {getPostCategorySelectView()}
            <div className="form-group">
              <div className="col-sm-12">
                <button type="button" onClick={postClick} className="btn btn-primary">
                  Kaydet
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

}
export default InsertCategory;