import React, { useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SimpleCard } from "@gull";
import globalTypes from "helpers/globalTypes";
import network from "networking/network";
import { Button, Form } from "react-bootstrap";
import localStorageService from "app/services/localStorageService";
import Swal from "sweetalert2";
import { Loading } from "@gull";
import helperFunc from "helpers/helperFunc";
import NotificationSend from "Component/NotificationSend";
import { Dropdown, } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faEdit, faCopy, faBell, faUpload, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Col, Row, Table } from 'react-bootstrap';
import MUIDataTable, { ExpandButton } from "mui-datatables";
import f7WebNetwork from "networking/f7WebNetwork";


const PostList = () => {


    const notificationSendRef = useRef();
    const [userInfo, setUserInfo] = useState({
        UserId: "",
        UserName: "",
        NameSurname: "",
        UserEmail: "",
        UserImage: "",
        UserType: -1
    });

    const [poostList, setPostList] = useState([]);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [pagingOptions, setPagingOptions] = useState({
        start: 0,
        end: 2000
    });
    const history = useHistory();

    useEffect(() => {
        setLoadingVisible(true);


        selectUserInfo();


    }, []);

    useEffect(() => {
        selectPostListWithPaging();
    }, [pagingOptions]);
    // const selectPostList = () => {


    //     f7WebNetwork.get("/admin/selectAllPost")
    //         .then(result => {
    //             setLoadingVisible(false);
    //             var data = result.data ||[];
    //             setPostList(data);

    //         })
    // }
    const selectPostListWithPaging = () => {

        var params = {
            start: pagingOptions.start,
            end: pagingOptions.end
        }
        setLoadingVisible(true);
        f7WebNetwork.get("/admin/selectAllPostPaging", params)
            .then(result => {
                setLoadingVisible(false);
                var data = result.data || [];
                setPostList(data);

            })
    }
    const clearCache = () => {


        f7WebNetwork.get("/admin/clearAllCache")
            .then(result => {


            })
    }
    const selectUserInfo = () => {

        var user =helperFunc.getUserInformation();

        setUserInfo(user);

    }

    const sendPostNotification = async (item) => {

        var imageUrl = helperFunc.getNewsImage(item.MAINIMAGE);
        notificationSendRef.current.openModal(item.POSTID, item.POSTTITLE, item.SHORTDESCRIPTION, imageUrl, item.LESSONGROUPID);


    }

    const changePostStatusFromLocal = (postId, statusText) => {
        var postListnew = JSON.parse(JSON.stringify(poostList));

        var postIndex = postListnew.findIndex(w => w.POSTID == postId);
        if (postIndex != -1) {
            postListnew[postIndex].POSTSTATUS = statusText;
            setPostList(postListnew);
        }
    }
    const changePostNotificationStatusFromLocal = (postId, status) => {
        var postListnew = JSON.parse(JSON.stringify(poostList));

        var postIndex = postListnew.findIndex(w => w.POSTID == postId);
        if (postIndex != -1) {
            postListnew[postIndex].ISSENDNOTIFICATION = status;
            setPostList(postListnew);
        }
    }

    const newsMakePassive = (postId) => {


        if (window.confirm("Haber pasife alınacak. Devam edilsin mi?")) {
            setLoadingVisible(true);

            var params = {
                PostId: postId,
                StatusText: "passive",
                UserId: userInfo.UserId
            };
            f7WebNetwork.post("/admin/changePostStatus/", params)
                .then(result => {
                    setLoadingVisible(false);
                    var data = result.data;

                    if (data.isSuccess) {
                        Swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Ok",
                            position: "top-end",
                            icon: "success",
                            text: "Pasife alındı..",
                            allowOutsideClick: true,
                            timer: 3000
                        });

                        changePostStatusFromLocal(postId, "passive");
                        // selectCategoryList();


                    } else {
                        setLoadingVisible(false);
                        alert(data.errorMessage);
                    }

                })
        }
    }

    const getPagingList =()=>{

        var list =[];
        for (let i = 0; i < 50; i++) {
            var start = i*2000;
            var end = start + 2000
            list.push({
                start : start,
                end : end
            });
            
        }
        return list;
    }
    const newsMakeActive = (postId) => {

        if (window.confirm("Haber Aktife alınacak. Devam edilsin mi?")) {
            setLoadingVisible(true);
            var params = {
                PostId: postId,
                StatusText: "active",
                UserId: userInfo.UserId
            };
            f7WebNetwork.post("/admin/changePostStatus/", params)
                .then(result => {
                    setLoadingVisible(false);
                    var data = result.data;

                    if (data.isSuccess) {
                        Swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Tamam",
                            position: "top-end",
                            icon: "success",
                            text: "Aktife Alındı..",
                            allowOutsideClick: true,
                            timer: 3000
                        });

                        changePostStatusFromLocal(postId, "active");

                    } else {
                        alert(data.errorMessage);
                    }

                })
        }
    }



    const getDeleteButton = (row) => {
        var rowStatus = true;
        if (row.POSTSTATUS == "passive") {
            rowStatus = false;
        }
        if (row.POSTSTATUS == "draft") {

            return (
                <>
                    <a variant="outline-primary primary" className="table-buttons" onClick={() => { newsMakeActive(row.POSTID) }}>

                        <FontAwesomeIcon icon={faUpload} color="green" size={"lg"} />
                    </a>
                </>
            );
        }

        if (rowStatus) {
            return (
                <Link variant="danger" className="table-buttons" onClick={() => { newsMakePassive(row.POSTID) }}>

                    <FontAwesomeIcon icon={faCheckCircle} color="green" size={"lg"} />
                </Link>
            );
        } else {
            return (
                <Link variant="outline-primary primary " className="table-buttons" onClick={() => { newsMakeActive(row.POSTID) }}>

                    <FontAwesomeIcon icon={faCheckCircle} color="red" size={"lg"} />
                </Link>
            );
        }
    }


    const getNotificationButton = (row) => {

        var notifyCheck = [];

        if (row.ISSENDNOTIFICATION) {
            notifyCheck = [
                <FontAwesomeIcon key={row.POSTID + ""} icon={faBell} color="green" size={"lg"} />
            ];
            // return (
            //     <Link variant="outline-primary btn" className=" table-buttons">
            //         <FontAwesomeIcon icon={faBell} color="green" size={"lg"} />
            //     </Link>
            // );
        } else {
            notifyCheck = [
                <FontAwesomeIcon key={row.POSTID + ""} icon={faBell} size={"lg"} />
            ];
        }
        return (
            <Link variant="outline-primary btn" className="table-buttons" onClick={() => { sendPostNotification(row) }}>
                {notifyCheck}
            </Link>
        );
    }



    const buttonFormatter = (cell, row2) => {

        const rowIndex = poostList.findIndex(w => w.POSTID == row2[0]);
        const row = poostList[rowIndex];

        return (
            <div className="row align-items-center justify-content-center " style={{ width: 200 }}>

                {getDeleteButton(row)}
                <Link variant="outline-secondary" className=" table-buttons" style={{}} target="_blank"
                    onClick={() => {
                        window.open(globalTypes.webProjectUrl + helperFunc.getNewsUrlObj(row.POSTTITLE, row.POSTID).to);
                    }}

                >
                    <FontAwesomeIcon icon={faExternalLinkAlt} size={"lg"} />

                </Link>
                {getNotificationButton(row)}
                <Link to={"/haber/haberEkle/" + row.POSTID + "/edit"} variant="outline-primary btn  " className="table-buttons" style={{}} title="Düzenle" onClick={() => { }}>
                    <FontAwesomeIcon icon={faEdit} size={"lg"} />
                </Link>
                <Link to={"/haber/haberEkle/" + row.POSTID + "/copy"} variant="outline-primary btn " className="table-buttons" style={{}} title="Kopyala" onClick={() => { }}>

                    <FontAwesomeIcon icon={faCopy} size={"lg"} />
                </Link>
            </div>
        );
    }


    const paginationOpt = globalTypes.paginationOptions;
    paginationOpt.totalSize = poostList.length;
    paginationOpt.sizePerPage = 30;

    const rangeList = getPagingList();

    return (
        <div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <div  style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                    <Button onClick={() => {
                        history.push({
                            pathname: "/haber/haberEkle/0/new"
                        });
                    }} className="btn-icon m-1 text-capitalize" style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "row", marginRight: 15 }} variant="outline-primary">

                        <span className="ul-btn__icon">
                            <i className="i-Add"></i>
                        </span>
                        <span className="ul-btn__text">Haber Ekle</span>
                    </Button>

                    <Button onClick={clearCache} className="btn-icon m-1 text-capitalize" style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "row", marginRight: 15 }} variant="outline-primary">

                        <span className="ul-btn__icon">
                            <i className="i-Reload"></i>
                        </span>
                        <span className="ul-btn__text">Önbelleği Temizle</span>
                    </Button>
                </div>

                    <div>
                        <label style={{
                            marginRight:10
                        }}>
                            Haber Gösterim Aralığı: 
                        </label>
                        <select onChange={(ev)=>{
                            var value = ev.target.value;
                            var rangeList = value.split("_");
                            setPostList([]);
                            setPagingOptions({
                                end:rangeList[1],
                                start:rangeList[0]
                            })
                        }} value={pagingOptions.start + "_" + pagingOptions.end} >
                            <option disabled> Aralık Seçiniz</option>
                            <option value={"0_1500000"}> Tümünü Göster</option>
                            {rangeList.map((item, index)=>{

                                return(
                                    <option key={"select" + index} value={item.start + "_" + item.end}> {item.start + " - "+ item.end +" Arası"}</option>
                                )
                            })}
                        </select>

                    </div>
            </div>


            <NotificationSend changePostNotificationStatusFromLocal={changePostNotificationStatusFromLocal} UserId={userInfo.UserId} ref={notificationSendRef}></NotificationSend>
            <div>
                {loadingVisible ?(
                    <div>
                        Yükleniyor...
                    </div>
                ): (
   <MUIDataTable
                    title={"Haber Listesi"}
                    data={poostList}
                    
                    columns={[
                        {
                            name: "POSTID",
                            label: "Id",
                            sort: true,
                            style: {
                                width: 50,
                                columnSpan: 1
                            },

                        },
                        {
                            name: "POSTTITLE",
                            label: "Haber Başlığı",
                            sort: true,
                            search: true,
                            width: 250,
                            style: {
                                columnSpan: 3
                            },
                        },
                        {
                            name: "POSTDATEXMLSTR",
                            label: "Ekleme Tar.",
                            sort: true
                        },
                        {
                            name: "TERMNAME",
                            label: "Kategori Adı",
                            sort: true
                        },
                        {
                            options: {
                                customBodyRender: (row, tableMeta) => buttonFormatter(null, tableMeta.rowData)
                            },
                            name: 'actions',
                            label: 'Actions',
                            isDummyField: true,
                            csvExport: false,
                            width: 200,
                            formatter: buttonFormatter,
                            headerStyle: {
                                color: 'white'
                            }
                        }
                    ]}
                    options={{
                        download: false,
                        print: false,
                        viewColumns: false,
                        filterType: "multiselect",
                        isRowSelectable: false,
                        selectableRowsHeader: false,
                        rowsPerPage: 50,
                        selectableRows: "none"
                    }}
                />
                )}
                
            </div>

        </div>
    );

}
export default PostList;