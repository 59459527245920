import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SimpleCard } from "@gull";
import globalTypes from "helpers/globalTypes";
import network from "networking/network";
import { Button } from "react-bootstrap";
import localStorageService from "app/services/localStorageService";
import Swal from "sweetalert2";
import { Loading } from "@gull";
import f7WebNetwork from "networking/f7WebNetwork";
import helperFunc from "helpers/helperFunc";


const CategoryList = () => {

    const [userInfo, setUserInfo] = useState({
        UserId: "",
        UserName: "",
        NameSurname: "",
        UserEmail: "",
        UserImage: "",
        UserType: -1
    });

    const [categoryList, setCategoryList] = useState([]);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoadingVisible(true);

        selectCategoryList();
        selectUserInfo();
    }, []);
    const selectCategoryList = () => {

        f7WebNetwork.get("/admin/selectAllCategory")
            .then(result => {
                setLoadingVisible(false);
                var data = result.data;
       
                if (data) {
                    setCategoryList(data);
                }
            })
    }
    const selectUserInfo = () => {
 
        var user = helperFunc.getUserInformation();

        setUserInfo(user);

    }

    const deleteCategoryPreview = (termId) => {

        if (window.confirm("Kategori kalıcı olarak silinecek. Devam edilsin mi?")) {

            var params = {
                TERMID: termId,
                NAME: "",
                SLUG: "",
                DISPLAYORDER: 0,
                ISMAINCATEGORY: false,

            };
            f7WebNetwork.post("/admin/deleteCategory/", params)
                .then(result => {

                    var data = result.data;

                    if (data.isSuccess) {
                        Swal.fire({
                            title: "Başarılı",
                            confirmButtonText: "Ok",
                            position: "top-end",
                            icon: "success",
                            text: "Kategori Silindi..",
                        });
                        selectCategoryList();

                    } else {
                        setLoadingVisible(false);
                        alert(data.errorMessage);
                    }

                })

        }

    }
    const getDeleteButton = (row) => {

        if (userInfo.UserType == globalTypes.loginUserType.superAdmin) {

            return (
                <Button variant="danger" onClick={() => { deleteCategoryPreview(row.TERMID) }}> <i className="i-Delete-File"> </i>  Sil</Button>
            );
        }
        return null;
    }

    const buttonFormatter = (cell, row) => {


        return (
            <>
                <Link to={"/kategori/kategoriEkle/" + row.TERMID} variant="outline-primary" style={{ marginRight: 15 }} onClick={() => { }}> <i className="i-Edit"> </i> Düzenle</Link>

                {getDeleteButton(row)}
            </>
        )
    }


  /*
  DISPLAYORDER: 2
ICON: ""
ISMAINCATEGORY: true
NAME: "Kamu"
PARENTID: -1
SLUG: "kamu"
TERMID: 13
  */

    const sortableColumn = [
        {
            dataField: "TERMID",
            text: "Id",
            sort: true
        },
        {
            dataField: "NAME",
            text: "Kategori Adı",
            sort: true
        },
        {
            dataField: "SLUG",
            text: "Kategori Url",
            sort: true
        },
        {
            dataField: "DISPLAYORDER",
            text: "Görünüm Sırası",
            sort: true
        },
        {
            dataField: "ISMAINCATEGORY",
            text: "Ana Sayfa Kategorisi mi?",
            sort: true,
            align: "center",
            headerAlign: "center",
            formatter: (cell) => {
                if (cell) {
                    return "Evet"
                }
                return "Hayır"
            },

        },
        {
            dataField: 'actions',
            text: 'Actions',
            isDummyField: true,
            csvExport: false,
            formatter: buttonFormatter,
            headerStyle: {
                color: 'white'
            }
        }
    ];


    const paginationOpt = globalTypes.paginationOptions;
    paginationOpt.totalSize = categoryList.length;

    const getLoadingPanel = () => {

        if (loadingVisible) {

            return (
                <Loading ></Loading>
            );
        } else {
            return null;
        }
    }

    return (
        <div>
            {getLoadingPanel()}

            <Button onClick={() => {
                history.push({
                    pathname: "/kategori/kategoriEkle/0"
                });
            }} className="btn-icon m-1 text-capitalize" style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "row", marginRight: 15 }} variant="outline-primary">

                <span className="ul-btn__icon">
                    <i className="i-Add"></i>
                </span>
                <span className="ul-btn__text">Kategori Ekle</span>

            </Button>


            <div>

                <SimpleCard title="Kategori Listesi">
                    <BootstrapTable
                        bootstrap4
                        keyField="TERMID"
                        data={categoryList}
                        columns={sortableColumn}

                        // defaultSorted={this.defaultSorted}
                        pagination={paginationFactory(paginationOpt)}
                        noDataIndication={"Kategori bulunmamaktadır."}

                    />
                </SimpleCard>
            </div>

        </div>
    );

}
export default CategoryList;