import { Button, Popover, OverlayTrigger, Tab, Tabs, Dropdown } from "react-bootstrap";
import PostImageListModal from "Component/PostImageListModal";
import globalTypes from "helpers/globalTypes";
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { Loading, SimpleCard } from "@gull";
import PostHtmlDesigner from "../../Component/PostHtmlDesigner/index"
import PostInsertSeoArea from "Component/PostInsertSeoArea";
import PostPreview from "Component/PostPreview";
import network from "networking/network";
import helperFunc from "helpers/helperFunc";
import localStorageService from "app/services/localStorageService";
import Swal from "sweetalert2";
import Switch from "react-switch";
import HtmlEditor from "Component/HtmlEditor";
import ReleatedPostListModal from "Component/ReleatedPostListModal";
import CategoryListSelectBox from "Component/CategoryListSelectBox";
import f7WebNetwork from "networking/f7WebNetwork";

const mainImageType = {
    fromRepository: 1,
    fromUrl: 2,
    fromUpload: 3
}


/*
  ,[BESLIMANSET]
      ,[ANAMANSET]
      ,[SAGMANSET]
      ,[HABERBANDI]
      ,[OZELHABERLER]
      ,[GUNUNMANSETI]
      ,[ARAMANSET]
      ,[YATAYHABER]
      ,[SAGMANSETIMAGE]
*/

const InsertUpdatePost = () => {

    const mainImageFileUploadRef = useRef();
    const sagMansetImageFileUploadRef = useRef();
    const undoClick = useRef(false);
    const htmlContentRef = useRef("");
    const htmlEditorRef = useRef();

    const [imageLibraryModalVisible, setImageLibraryModalVisible] = useState(false);
    const [selectedMainImage, setSelectedMainImage] = useState("");
    const [selectedMainImageDimension, setSelectedMainImageDimension] = useState({
        width: 0,
        height: 0
    });

    const [InsertPostState, setInsertPostState] = useState({
        selectedMainImageType: "",
        mainImageUrl: "",
        mainImageAlt: "",
        mainImageFromFile: "",
        postTitle: "",
        postShortDesc: "",
        selectedTagList: [],
        selectedPostCategory: -1,
        postKeywords: "",
        seoDescription: "",
        contentHtml: "",
        selectedMainImage: "",
        previewInformation: null,
        nextStateInformation: null,
        lessonGroupId: -1,

        BESLIMANSET: false,
        ANAMANSET: false,
        SAGMANSET: false,
        HABERBANDI: false,
        OZELHABERLER: false,
        GUNUNMANSETI: false,
        ARAMANSET: false,
        YATAYHABER: false,
        SAGMANSETIMAGE: "",
        SAGMANSETIMAGEFILE: "",
        SAGMANSETIMAGEALT: "",
        SAGMANSETIMAGEFROM: -1,

        ISVIDEO: false,
        VIDEONAME: "",
        RELEATEDPOSTIDS: []
    });

    const [postCategoryList, setPostCategoryList] = useState([]);

    const [loadingVisible, setLoadingVisible] = useState(false);
    const [releatedNewsModalVisible, setReleatedNewsModalVisible] = useState(false);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [userInfo, setUserInfo] = useState({
        UserId: "",
        UserName: "",
        NameSurname: "",
        UserEmail: "",
        UserImage: "",
        UserType: -1
    });

    let { haberId, mode } = useParams();
    const history = useHistory();

    useEffect(() => {

        //         const script = document.createElement('script');

        //   script.src = "https://platform.twitter.com/widgets.js";
        //   script.async = true;

        //   document.body.appendChild(script);


        selectPostCategoryList();
        const onUnload = ev => ev.returnValue = 'Are you sure you want to close?';

        var user = helperFunc.getUserInformation();

        setUserInfo(user);

        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return ev.returnValue = 'Are you sure you want to close?';
        });
        return () => window.removeEventListener("beforeunload", onUnload);

    }, []);


    useEffect(() => {

        if (parseInt(haberId) > 0) {

            selectPostInformation();
        }

    }, [haberId]);


    const commonSetInsertPostState = (updatedState) => {

        setInsertPostState({

            ...InsertPostState,
            previewInformation: (InsertPostState),
            ...updatedState,

        });
    }

    const undoState = () => {
        undoClick.current = true;

        if (InsertPostState && InsertPostState.previewInformation) {
            setInsertPostState({
                ...InsertPostState.previewInformation,
                nextStateInformation: (InsertPostState),
            });
        }


    }
    const redoState = () => {
        undoClick.current = true;
        if (InsertPostState && InsertPostState.nextStateInformation) {
            setInsertPostState({
                ...InsertPostState.nextStateInformation,
            });
        }
    }

    const selectPostInformation = async () => {

        var params = {
            PostId: parseInt(haberId)
        }
        var imagePostResult = await f7WebNetwork.get("/adminblogpost/selectPostInformation", params);

        var post = imagePostResult.data;
        if (post && (post).length > 0) {
            var singlePost = post[0];


            var tags = (singlePost.TAGS || "").split(",");

            var taglist = [];
            for (let i = 0; i < tags.length; i++) {

                taglist.push({
                    id: tags[i],
                    text: tags[i]
                });
            }
            var htmlContent = singlePost.POSTCONTENT;
            var releatedPosts = (singlePost.RELEATEDPOSTIDS || "").split(",");


            htmlContentRef.current = htmlContent;
            const updateState = {
                selectedPostCategory: singlePost.CATEGORYID,
                postTitle: singlePost.POSTTITLE,
                selectedMainImage: singlePost.MAINIMAGE,
                mainImageAlt: singlePost.MAINIMAGEALT || "",
                selectedMainImageType: mainImageType.fromRepository,
                postKeywords: singlePost.KEYWORDS || "",
                selectedTagList: taglist,
                seoDescription: singlePost.METADESCRIPTION || "",
                postShortDesc: singlePost.SHORTDESCRIPTION || "",
                contentHtml: htmlContent,
                lessonGroupId: singlePost.LESSONGROUPID,


                BESLIMANSET: singlePost.BESLIMANSET,
                ANAMANSET: singlePost.ANAMANSET,
                SAGMANSET: singlePost.SAGMANSET,
                HABERBANDI: singlePost.HABERBANDI,
                OZELHABERLER: singlePost.OZELHABERLER,
                GUNUNMANSETI: singlePost.GUNUNMANSETI,
                ARAMANSET: singlePost.ARAMANSET,
                YATAYHABER: singlePost.YATAYHABER,
                SAGMANSETIMAGE: singlePost.SAGMANSETIMAGE,
                SAGMANSETIMAGEFROM: mainImageType.fromRepository,
                SAGMANSETIMAGEFILE: singlePost.SAGMANSETIMAGEFILE,
                SAGMANSETIMAGEALT: singlePost.SAGMANSETIMAGEALT,

                ISVIDEO: singlePost.ISVIDEO,
                VIDEONAME: singlePost.VIDEONAME,
                RELEATEDPOSTIDS: releatedPosts,
            }

            commonSetInsertPostState(updateState);

        } else {
            alert(imagePostResult.errorMessage);
        }
    }


    const clearMainmage = () => {

        const updateState = {
            selectedMainImage: ""
        }

        commonSetInsertPostState(updateState);

        // setSelectedMainImage("");
    }
    const clearSagMansetmage = () => {

        const updateState = {
            SAGMANSETIMAGE: ""
        }

        commonSetInsertPostState(updateState);

        // setSelectedMainImage("");
    }
    function checkURL(url) {
        return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }

    const loadImageFromUrl = () => {
        if (InsertPostState.mainImageUrl && InsertPostState.mainImageUrl.length > 0) {
            if (checkURL(InsertPostState.mainImageUrl)) {

                const updateState = {
                    selectedMainImageType: mainImageType.fromUrl,
                    selectedMainImage: InsertPostState.mainImageUrl
                }

                commonSetInsertPostState(updateState);
                // setSelectedMainImageType(mainImageType.fromUrl);
                // setSelectedMainImage(mainImageUrl);
            } else {
                alert("Fotoğraf bağlantısı yanlış girilmiştir.");
            }
        } else {
            alert("Fotoğraf Bağlantısını Giriniz");
        }
    }

    const selectPostCategoryList = () => {


        f7WebNetwork.get("/admin/selectAllCategory")
            .then(result => {
                var data = result.data;

                if (data) {
                    setPostCategoryList(data);
                }
            })


    }


    const onFileUpload = (event) => {

        var file_upload = event.target.files[0];

        const formData = new FormData();

        // Update the formData object
        formData.append(
            "myFile",
            file_upload,
            file_upload.name
        );

        // Details of the uploaded file

        const updateState = {
            selectedMainImageType: mainImageType.fromUpload,
            selectedMainImage: URL.createObjectURL(file_upload),
            mainImageFromFile: event.target.files[0]
        }

        commonSetInsertPostState(updateState);
    };
    const onSagMansetFileUpload = (event) => {

        var file_upload = event.target.files[0];

        const formData = new FormData();

        // Update the formData object
        formData.append(
            "myFile",
            file_upload,
            file_upload.name
        );

        // Details of the uploaded file

        const updateState = {

            SAGMANSETIMAGE: URL.createObjectURL(file_upload),
            SAGMANSETIMAGEFILE: event.target.files[0],
            SAGMANSETIMAGEFROM: mainImageType.fromUpload,
        }

        commonSetInsertPostState(updateState);
    };

    const postClickPreview = () => {

        const html_content = htmlEditorRef.current.getEditorValue() || "";
        if (html_content.length == 0) {
            alert("Haber Tasarımı boş geçilemez...");
            return;
        }
        var mainImageUrl = getMainImageFullUrl();
        if (mainImageUrl.length == 0 || InsertPostState.postTitle.length == 0 || InsertPostState.postShortDesc.length == 0 || InsertPostState.selectedPostCategory.toString().length == 0) {
            alert("Ana Bilgiler bölümünde boş alan bulunmaktadır...");
            return;
        }
        if (InsertPostState.seoDescription.length == 0 || InsertPostState.selectedTagList.length == 0 || InsertPostState.postKeywords.length == 0) {
            alert("Seo Alanı bölümünde boş alan bulunmaktadır...");
            return;
        }

        postNewSubmit();
    }

    const postNewSubmit = async (isDraft) => {
        setLoadingVisible(true);
        const html_content = htmlEditorRef.current.getEditorValue() || "";

        var mainImageInsert = false;
        var imageObjectList = [];
        var mainImage = "";

        if (InsertPostState.selectedMainImageType == mainImageType.fromUpload) {
            mainImageInsert = true;
            var base64 = await fileToBase64(InsertPostState.mainImageFromFile);
            mainImage = base64;
            // imageObjectList.push({
            //     imageType: "mainImage",
            //     base64String: base64,
            //     imageName: helperFunc.getBeautyTitle(InsertPostState.postTitle),
            //     listIndex: -1,
            // });
        } else if (InsertPostState.selectedMainImageType == mainImageType.fromUrl) {
            mainImageInsert = true;
            var base64 = await UrlToBase64(InsertPostState.mainImageUrl);
            mainImage = base64;
            // imageObjectList.push({
            //     imageType: "mainImage",
            //     base64String: base64,
            //     imageName: helperFunc.getBeautyTitle(InsertPostState.postTitle),
            //     isUrlImage: false,
            //     listIndex: -1,
            // });
        }
        var sagMansetIsInsert = false;
        var sagmansetImage = "";
        if (InsertPostState.SAGMANSETIMAGE && InsertPostState.SAGMANSETIMAGEFROM == mainImageType.fromUpload) {

            sagMansetIsInsert = true;

            var base64 = await UrlToBase64(InsertPostState.SAGMANSETIMAGE);

            sagmansetImage = base64;
            // imageObjectList.push({
            //     imageType: "sagManset",
            //     base64String: base64,
            //     imageName: helperFunc.getBeautyTitle(InsertPostState.postTitle + "-manset"),
            //     isUrlImage: false,
            //     listIndex: -1,
            // });
        }


        // var imageResultData = [];
        // if (imageObjectList.length > 0) {
        //     // network.post("blogpostadmin/saveNewsImages/", imageObjectList);
        //     // network.post("blogpostadmin/saveNewsImagesWithName/", imageObjectList);
        //     var imagePostResult = await f7WebNetwork.post("/admin/saveNewsImages", imageObjectList);
        //     console.log(imagePostResult);
        //     imageResultData = imagePostResult.data
        // }


        var postimageSuccess = true;
        // for (let i = 0; i < imageResultData.length; i++) {
        //     const element = imageResultData[i];
        //     if (element.isSuccessSave == false) {
        //         postimageSuccess = false;
        //     }
        // }
        // if (postimageSuccess != true) {
        //     setLoadingVisible(false);
        //     alert("Resimler düzgün bir şekilde kayıt edilemedi lütfen kotrnol edip tekrar deneyiniz.");
        //     return;
        // }
        var mainImageName = "";
        var mainImageWidth = 0;
        var mainImageHeight = 0;
        // if (mainImageInsert) {
        //     mainImageName = (imageResultData && imageResultData.length > 0) ? imageResultData[0].imageName : "";
        //     mainImageWidth = (imageResultData && imageResultData.length > 0) ? imageResultData[0].imageWidth : 0;
        //     mainImageHeight = (imageResultData && imageResultData.length > 0) ? imageResultData[0].imageHeight : 0;


        //     imageResultData.shift()
        // }
        if (InsertPostState.selectedMainImageType == mainImageType.fromRepository) {
            mainImageName = InsertPostState.selectedMainImage;
            mainImageWidth = selectedMainImageDimension.width;
            mainImageHeight = selectedMainImageDimension.height;
        }
        var sagMansetImage = InsertPostState.SAGMANSETIMAGE;
        // if (sagMansetIsInsert) {
        //     var index = imageResultData.findIndex(w => w.imageType == "sagManset");

        //     sagMansetImage = imageResultData[index].imageName
        // }

        var tagisd = [];
        for (let j = 0; j < InsertPostState.selectedTagList.length; j++) {
            const element = InsertPostState.selectedTagList[j];
            tagisd.push(element.id);

        }
        var posttype = "1";
        const categoryId = InsertPostState.selectedPostCategory;
        const categoryindex = postCategoryList.findIndex(w => w.id == categoryId);
        if (categoryindex != -1 && postCategoryList[categoryindex].CATEGORYTYPE == 2) {
            posttype = "2";
        }
        var postId = (parseInt(haberId || 0))
        if (mode == "copy") {
            postId = 0;
        }

        var postModel = {
            POSTID: postId,
            CATEGORYID: categoryId > 0 ? parseInt(categoryId) : 0,
            POSTAUTHOR: userInfo.UserId,
            POSTCONTENT: html_content,
            POSTTITLE: InsertPostState.postTitle,
            POSTSTATUS: isDraft ? "draft" : "active",
            MAINIMAGE: mainImageName,
            IMAGEWIDTH: mainImageWidth,
            IMAGEHEIGHT: mainImageHeight,
            KEYWORDS: InsertPostState.postKeywords,
            TAGS: tagisd.join(","),
            METADESCRIPTION: InsertPostState.seoDescription,
            SHORTDESCRIPTION: InsertPostState.postShortDesc,
            POSTDATE: null,
            LASTUPDATE: null,
            POSTPARENTID: 0,
            ISVIDEO: InsertPostState.ISVIDEO,
            VIDEONAME: InsertPostState.VIDEONAME,
            MAINIMAGEALT: InsertPostState.mainImageAlt,
            POSTTYPE: posttype,
            LESSONGROUPID: parseInt(InsertPostState.lessonGroupId),



            BESLIMANSET: InsertPostState.BESLIMANSET,
            ANAMANSET: InsertPostState.ANAMANSET,
            SAGMANSET: InsertPostState.SAGMANSET,
            HABERBANDI: InsertPostState.HABERBANDI,
            OZELHABERLER: InsertPostState.OZELHABERLER,
            GUNUNMANSETI: InsertPostState.GUNUNMANSETI,
            ARAMANSET: InsertPostState.ARAMANSET,
            YATAYHABER: InsertPostState.YATAYHABER,
            SAGMANSETIMAGE: sagMansetImage,
            RELEATEDPOSTIDS: InsertPostState.RELEATEDPOSTIDS.join(","),


            SAGMANSETIMAGEBASE64: sagmansetImage,
            SAGMANSETIMAGENAME: helperFunc.getBeautyTitle(InsertPostState.postTitle + "-manset"),
            MAINIMAGEBASE64: mainImage,
            MAINIMAGENAME: helperFunc.getBeautyTitle(InsertPostState.postTitle)
        }

        var postResult;
        if (postModel.POSTID > 0) {
            // postResult = await network.post("blogpostadmin/updatePost/", postModel);
            postResult = await f7WebNetwork.post("/adminblogpost/updatePost/", postModel);
        } else {
            // postResult = await network.post("blogpostadmin/insertNewPost/", postModel);
            postResult = await f7WebNetwork.post("/adminblogpost/insertNewPost/", postModel);
        }
        
        setLoadingVisible(false);
        if (postResult.isSuccess) {
            var res = postResult.data;
            if (res.isSuccess) {

                Swal.fire({
                    title: "Başarılı",
                    confirmButtonText: "Ok",
                    position: "top-end",
                    icon: "success",
                    text: "Haber başarılı bir şekilde eklendi..",
                });
                history.push({
                    pathname: "/haber/haberlistesi"
                });
            } else {
                alert(res.errorMessage);
            }
            // public bool IsSuccess { get; set; }
            // public string ErrorMessage { get; set; }
            // public string DocumentId { get; set; }
            // public string ReturnJson { get; set; }

        } else {
            alert("Bağlantı hatası meydana geldi...");
            return;
        }


    }

    const fileToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const UrlToBase64 = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))




    const getMainImageFullUrl = () => {

        var imageUrl = "";

        if (InsertPostState.selectedMainImageType == mainImageType.fromUrl) {
            imageUrl = InsertPostState.mainImageUrl;
        } else if (InsertPostState.selectedMainImageType == mainImageType.fromUpload) {
            imageUrl = InsertPostState.selectedMainImage;
        } else {
            imageUrl = globalTypes.serviceUrl + globalTypes.AppServicesAssetsPath.PostImagesPath + InsertPostState.selectedMainImage;
        }
        return imageUrl;
    }
    const getSagMansetImageFullUrl = () => {

        var imageUrl = "";
        if (InsertPostState.SAGMANSETIMAGEFROM == mainImageType.fromRepository) {
            imageUrl = globalTypes.serviceUrl + globalTypes.AppServicesAssetsPath.SagMansetPath + InsertPostState.SAGMANSETIMAGE;
        } else {
            imageUrl = InsertPostState.SAGMANSETIMAGE;
        }


        return imageUrl;
    }

    const onImgLoad = ({ target: img }) => {
        setSelectedMainImageDimension(
            {
                height: img.offsetHeight,
                width: img.offsetWidth
            }
        );
    }
    function getReleatedPostList() {
        var postids = InsertPostState.RELEATEDPOSTIDS


        return JSON.parse(JSON.stringify(postids));
    }
    const addPostId = (postid) => {

        var idListr = InsertPostState.RELEATEDPOSTIDS;


        idListr.push(postid);

        const updateState = {
            RELEATEDPOSTIDS: idListr
        }

        commonSetInsertPostState(updateState);
    }
    const deletePostId = (postid) => {

        var idListr = InsertPostState.RELEATEDPOSTIDS;
        var index = idListr.indexOf(postid);
        if (index != -1) {
            idListr.splice(index, 1);
        }
        const updateState = {
            RELEATEDPOSTIDS: idListr
        }
        commonSetInsertPostState(updateState);
    }


    const getInsertImageArea = () => {

        if (InsertPostState.selectedMainImage && InsertPostState.selectedMainImage.length > 0) {

            var imageUrl = getMainImageFullUrl();

            return (
                <div className="justify-content-center">
                    <div className="row justify-content-center" style={{ width: "100%" }}>
                        <div className="com-lg-6 col-md-6 col-sm-6">
                            <img width="250" src={imageUrl} onLoad={onImgLoad}></img>
                        </div>

                    </div>

                    <div className=" ">
                        <Button
                            onClick={clearMainmage}
                            variant="danger"
                            className="btn-icon m-1 text-capitalize"
                        >
                            <span className="ul-btn__icon">
                                <i className="i-Close-Window"></i>
                            </span>
                            <span className="ul-btn__text">Fotoğrafı Kaldır</span>
                        </Button>
                    </div>
                </div>

            )

        } else {

            return (
                <>
                    <div className="row justify-content-center" style={{ width: "100%" }}>
                        {/* <div className="col-lg-6 col-md-6 col-sm-12" style={{ textAlign: "right" }}>

                            <Button variant={"outline-primary"} className="text-capitalize btn-icon" onClick={() => setImageLibraryModalVisible(true)}>
                                <span className="ul-btn__icon">
                                    <i className="i-Library"></i>
                                </span>
                                <span className="ul-btn__text">
                                    Kütüphaneden Seç
                                </span>
                            </Button>
                        </div> */}
                        <div className="col-lg-6 col-md-6 col-sm-12" style={{ textAlign: "left" }}>
                            <Button variant={"outline-primary"} className="text-capitalize outline btn-icon" onClick={() =>
                                mainImageFileUploadRef.current.click()
                            }>
                                <span className="ul-btn__icon">
                                    <i className="i-Add-File"></i>
                                </span>
                                <span className="ul-btn__text">
                                    Dosyadan Seç
                                </span>
                            </Button>

                            <input
                                ref={mainImageFileUploadRef}
                                style={{ visibility: "hidden" }}
                                type="file"
                                // accept="image/*"
                                accept=".jpeg, .jpg, .png, .mp4"
                                className="custom-file-input"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01"
                                onChange={onFileUpload}
                            />

                        </div>
                    </div>
                    <div style={{ margin: 5 }}></div>



                    <Dropdown>
                        <Dropdown.Toggle>Bağlantıdan Yükle</Dropdown.Toggle>
                        <Dropdown.Menu
                            className="dropdown-menu bg-transparent shadow-none p-0 m-0"
                            style={{ width: "320px" }}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div className="row" style={{ textAlign: "right" }}>
                                        <input
                                            onChange={(ev) => {
                                                // setMainImageUrl(ev.target.value) 

                                                const updateState = {
                                                    mainImageUrl: ev.target.value,
                                                }
                                                commonSetInsertPostState(updateState);

                                            }}
                                            value={InsertPostState.mainImageUrl}
                                            type="text"
                                            className="form-control"
                                            placeholder="Fotoğraf Url" />

                                        <div style={{ width: "100%", margin: 5 }}>
                                            <Button onClick={() => { loadImageFromUrl() }}>
                                                Yükle
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>


                </>
            );
        }
    }


    const getSagMansetImageArea = () => {

        if (InsertPostState.SAGMANSETIMAGE && InsertPostState.SAGMANSETIMAGE.length > 0) {

            var imageUrl = getSagMansetImageFullUrl();

            return (
                <div className="justify-content-center">
                    <div className="row justify-content-center" style={{ width: "100%" }}>
                        <div className="com-lg-6 col-md-6 col-sm-6">
                            <img width="250" src={imageUrl}
                            //  onLoad={onImgLoad}
                            ></img>
                        </div>

                    </div>

                    <div className=" ">
                        <Button
                            onClick={clearSagMansetmage}
                            variant="danger"
                            className="btn-icon m-1 text-capitalize"
                        >
                            <span className="ul-btn__icon">
                                <i className="i-Close-Window"></i>
                            </span>
                            <span className="ul-btn__text">Fotoğrafı Kaldır</span>
                        </Button>
                    </div>
                </div>

            )

        } else {

            return (
                <>
                    <div className="row justify-content-center" style={{ width: "100%" }}>

                        <div className="col-lg-12 col-md-12 col-sm-12" style={{}}>
                            <Button variant={"outline-primary"} className="text-capitalize outline btn-icon" onClick={() =>
                                sagMansetImageFileUploadRef.current.click()
                            }>
                                <span className="ul-btn__icon">
                                    <i className="i-Add-File"></i>
                                </span>
                                <span className="ul-btn__text">
                                    Dosyadan Seç
                                </span>
                            </Button>

                            <input
                                ref={sagMansetImageFileUploadRef}
                                style={{ visibility: "hidden" }}
                                type="file"
                                // accept="image/*"
                                accept=".jpeg, .jpg, .png, .mp4"
                                className="custom-file-input"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01"
                                onChange={onSagMansetFileUpload}
                            />

                        </div>
                    </div>
                </>
            );
        }
    }

    const getCategoryItem = () => {

        return postCategoryList.map((item, index) => {


            if (item.id == InsertPostState.selectedPostCategory) {

                return (
                    <option selected value={item.id}>{item.linkText}</option>
                );
            } else {
                return (
                    <option value={item.id}>{item.linkText}</option>
                );
            }

        }
        );
    }



    const geLessonGroupSelectView = () => {

        return (
            <div style={{}} className="  col-md-6">
                <label>
                    Sınavlara Yönelik Haber Mi?
                </label>

                <select className="form-control" id="sel1" onChange={(ev) => {
                    const updateState = {
                        lessonGroupId: ev.target.value
                    }

                    commonSetInsertPostState(updateState);
                }}>
                    <option selected disabled>Sınav Seç</option>
                    {getLessonGroupItem()}
                </select>

            </div>
        );
    }
    const getPostCategorySelectView = () => {


        return (
            <div className="row">
                <div className="col-md-6">
                    <label>
                        haber Kategorisi
                    </label>
                    <CategoryListSelectBox categoryList={postCategoryList} onSelectCategory={
                        (ev) => {
                            const updateState = {
                                selectedPostCategory: ev
                            }
                            commonSetInsertPostState(updateState);
                        }
                    } selectedCategory={InsertPostState.selectedPostCategory}>

                    </CategoryListSelectBox>
                    {/* <select className="form-control " id="sel1" onChange={(ev) => {
                        const updateState = {
                            selectedPostCategory: ev.target.value
                        }
                        commonSetInsertPostState(updateState);
                    }}>
                        <option selected disabled>Kategori Seç</option>
                        {getCategoryItem()}
                    </select> */}
                </div>
                {geLessonGroupSelectView()}
            </div>
        );
    }

    const getLessonGroupItem = () => {

        return globalTypes.LessonGroupList.map((item, index) => {


            if (item.value == InsertPostState.lessonGroupId) {

                return (
                    <option selected value={item.value}>{item.label}</option>
                );
            } else {
                return (
                    <option value={item.value}>{item.label}</option>
                );
            }

        }
        );
    }


    const getUploadImageContent = () => {


        return (
            <div style={{
                textAlign: "center", background: "#f3f3f4",
                maxWidth: 600,
            }}>
                <div className="row justify-content-center" style={{ width: "100%", margin: "auto" }}>
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="exampleFormControlTextarea1">
                            Ana Manşet Fotoğrafı
                            {"\n"}
                            637x332 Boyutunda
                        </label>
                        <input type="text" placeholder="Fotoğrafın Açıklaması" maxLength="250" value={InsertPostState.mainImageAlt} onChange={(val) => {
                            const updateState = {
                                mainImageAlt: val.target.value,
                            }
                            commonSetInsertPostState(updateState);
                        }}
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            style={{ backgroundColor: "white" }}
                            rows="2"
                        ></input>
                    </div>
                </div>

                {getInsertImageArea()}
            </div>
        );
    }
    const getUploadSagMansetImageContent = () => {


        return (
            <div style={{
                textAlign: "center", background: "#f3f3f4",
                maxWidth: 600,
            }}>
                <div className="row justify-content-center" style={{ width: "100%", margin: "auto" }}>
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="exampleFormControlTextarea1">
                            Sağ Manşet Fotoğrafı {"\n"}
                            313x331 Boyutunda
                        </label>
                        <input type="text" maxLength="250" placeholder="fotoğraf Açıklaması" value={InsertPostState.SAGMANSETIMAGEALT} onChange={(val) => {
                            const updateState = {
                                SAGMANSETIMAGEALT: val.target.value,
                            }
                            commonSetInsertPostState(updateState);
                        }}
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            style={{ backgroundColor: "white" }}
                            rows="2"
                        ></input>
                    </div>
                </div>

                {getSagMansetImageArea()}
            </div>
        );
    }
    const getLoadingPanel = () => {

        if (loadingVisible) {

            return (
                <Loading ></Loading>
            );
        } else {
            return null;
        }
    }

    return (
        <div>
            {getLoadingPanel()}
            <div className="row justify-content-between">
                <div className="row justify-content-left" style={{ justifyContent: "flex-end", margin: 15 }}>


                    <Button disabled={!(InsertPostState && InsertPostState.previewInformation)} variant={"info"} className="text-capitalize btn-icon" onClick={() => undoState()}>
                        <span style={{ fontSize: 20 }} className="ul-btn__icon">
                            <i className="i-Back1"></i>
                        </span>
                        <span className="ul-btn__text">
                            Geri Al
                        </span>
                    </Button>

                    <Button disabled={!(InsertPostState && InsertPostState.nextStateInformation)} variant={"info"} className="text-capitalize btn-icon" style={{ marginLeft: 15 }} onClick={() => redoState()}>
                        <span style={{ fontSize: 20 }} className="ul-btn__icon">
                            <i className="i-Next1"></i>
                        </span>
                        <span className="ul-btn__text">
                            İleri Al
                        </span>
                    </Button>

                </div>

                <div className="row justify-content-right" style={{ justifyContent: "flex-end", margin: 15 }}>

                    <Button variant={"info"} className="text-capitalize btn-icon" onClick={() => postNewSubmit(true)}>
                        <span className="ul-btn__icon">
                            <i className="i-Library"></i>
                        </span>
                        <span className="ul-btn__text">
                            Taslak Olarak Kaydet
                        </span>
                    </Button>

                    <Button variant={"outline-primary"} className="text-capitalize btn-icon" style={{ marginLeft: 15 }} onClick={() => postClickPreview()}>
                        <span className="ul-btn__icon">
                            <i className="i-Upload-Window"></i>
                        </span>
                        <span className="ul-btn__text">
                            Haberi Yayınla
                        </span>
                    </Button>
                </div>
            </div>
            <SimpleCard title="Haber Başlığı">

                <div className="row">
                    <div className="col-md-6">
                        <div>
                            {getPostCategorySelectView()}
                        </div>
                        <div className="mb-4"></div>

                        <input maxLength="500" type="text" className="form-control" placeholder="Haber Başığını Giriniz..."
                            value={InsertPostState.postTitle} onChange={(val) => {
                                const updateState = {
                                    postTitle: val.target.value,
                                }
                                commonSetInsertPostState(updateState);

                            }}
                        >
                        </input>
                        <div className="mb-4"></div>
                        <PostInsertSeoArea seoDescription={InsertPostState.seoDescription}
                            setSeoDescription={(seodesc) => {
                                const updateState = {
                                    seoDescription: seodesc,

                                }

                                commonSetInsertPostState(updateState);
                            }}

                            postKeywords={InsertPostState.postKeywords}
                            setPostKeywords={(keywords) => {
                                const updateState = {
                                    postKeywords: keywords,

                                }

                                commonSetInsertPostState(updateState);
                            }}

                            selectedTagList={InsertPostState.selectedTagList}
                            setSelectedTagList={(taglist) => {
                                // setSelectedTagList
                                const updateState = {

                                    selectedTagList: taglist,

                                }

                                commonSetInsertPostState(updateState);
                            }}  ></PostInsertSeoArea>

                        <div className="mb-4"></div>

                        <div className="form-group">
                            <label>
                                Haber Kısa Açıklaması
                            </label>

                            <textarea maxLength="500" value={InsertPostState.postShortDesc} onChange={(val) => {
                                //  setPostShortDesc(val.target.value)

                                const updateState = {
                                    postShortDesc: val.target.value
                                }
                                commonSetInsertPostState(updateState);

                            }}
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="2"
                                placeholder="Haber Kısa Açıklamasını Giriniz..."
                            ></textarea>
                        </div>
                    </div>


                    <div className="col-md-6 text-right">
                        <div className="row ">
                            <div className="col-md-6">
                                <label className="d-flex align-items-center">

                                    <Switch uncheckedIcon={null} onChange={(checked) => {
                                        const updateState = {
                                            BESLIMANSET: checked
                                        }
                                        commonSetInsertPostState(updateState);
                                    }} checked={InsertPostState.BESLIMANSET} />
                                    <span className="text-muted mt-2 mb-0 text-capitalize ml-2">5'li Manşet mi?</span>
                                </label>
                                <label className="d-flex align-items-center">

                                    <Switch uncheckedIcon={null} onChange={(checked) => {
                                        const updateState = {
                                            ANAMANSET: checked
                                        }
                                        commonSetInsertPostState(updateState);
                                    }} checked={InsertPostState.ANAMANSET} />
                                    <span className="text-muted mt-2 mb-0 text-capitalize ml-2">Ana Manşet mi?</span>
                                </label>
                                <label className="d-flex align-items-center">

                                    <Switch uncheckedIcon={null} onChange={(checked) => {
                                        const updateState = {
                                            SAGMANSET: checked
                                        }
                                        commonSetInsertPostState(updateState);
                                    }} checked={InsertPostState.SAGMANSET} />
                                    <span className="text-muted mt-2 mb-0 text-capitalize ml-2">Sağ Manşet mi?</span>
                                </label>
                                <label className="d-flex align-items-center">

                                    <Switch uncheckedIcon={null} onChange={(checked) => {
                                        const updateState = {
                                            HABERBANDI: checked
                                        }
                                        commonSetInsertPostState(updateState);
                                    }} checked={InsertPostState.HABERBANDI} />
                                    <span className="text-muted mt-2 mb-0 text-capitalize ml-2">Haber Bandı mı?</span>
                                </label>

                            </div>
                            <div className="col-md-6">


                                <label className="d-flex align-items-center">

                                    <Switch uncheckedIcon={null} onChange={(checked) => {
                                        const updateState = {
                                            OZELHABERLER: checked
                                        }
                                        commonSetInsertPostState(updateState);
                                    }} checked={InsertPostState.OZELHABERLER} />
                                    <span className="text-muted mt-2 mb-0 text-capitalize ml-2">Özel Haber mi?</span>
                                </label>

                                <label className="d-flex align-items-center">

                                    <Switch uncheckedIcon={null} onChange={(checked) => {
                                        const updateState = {
                                            GUNUNMANSETI: checked
                                        }
                                        commonSetInsertPostState(updateState);
                                    }} checked={InsertPostState.GUNUNMANSETI} />
                                    <span className="text-muted mt-2 mb-0 text-capitalize ml-2">Günün Manşeti mi?</span>
                                </label>

                                <label className="d-flex align-items-center">

                                    <Switch uncheckedIcon={null} onChange={(checked) => {
                                        const updateState = {
                                            ARAMANSET: checked
                                        }
                                        commonSetInsertPostState(updateState);
                                    }} checked={InsertPostState.ARAMANSET} />
                                    <span className="text-muted mt-2 mb-0 text-capitalize ml-2">Ara Manşet mi?</span>
                                </label>
                                <label className="d-flex align-items-center">

                                    <Switch uncheckedIcon={null} onChange={(checked) => {
                                        const updateState = {
                                            YATAYHABER: checked
                                        }
                                        commonSetInsertPostState(updateState);
                                    }} checked={InsertPostState.YATAYHABER} />
                                    <span className="text-muted mt-2 mb-0 text-capitalize ml-2">Yatay Haber mi?</span>
                                </label>
                            </div>

                            <div className="mt-4 col-12">
                                <label className="d-flex align-items-center">

                                    <Switch uncheckedIcon={null} onChange={(checked) => {
                                        const updateState = {
                                            ISVIDEO: checked
                                        }
                                        commonSetInsertPostState(updateState);
                                    }} checked={InsertPostState.ISVIDEO} />
                                    <span className="  mb-0 text-capitalize ml-2 font-weight-bold color-black">Video Haber mi?</span>
                                </label>

                                {InsertPostState.ISVIDEO ? (
                                    <textarea maxLength="500" value={InsertPostState.VIDEONAME} onChange={(val) => {
                                        const updateState = {
                                            VIDEONAME: val.target.value
                                        }
                                        commonSetInsertPostState(updateState);

                                    }}
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="2"
                                        placeholder="Video Embed Kodunu giriniz..."
                                    ></textarea>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </SimpleCard>
            <div className="mb-2"></div>
            <div className="mb-2"></div>
            <HtmlEditor
                ref={htmlEditorRef}
                content={htmlContentRef.current}
                placeholder="insert text here..."
            />
            <div className="mb-2"></div>


            <div className="mb-2"></div>
            <SimpleCard title="Haber Fotoğrafı">

                <div className="row">
                    <div className="col-6">
                        {getUploadImageContent()}
                    </div>
                    <div className="col-6">
                        {getUploadSagMansetImageContent()}
                    </div>
                </div>

            </SimpleCard>


            <div className="mt-4"></div>


            <div className="form-group dx-f">
                <label>
                    Bağlantılı Haberler
                </label>
                <div className="row">
                    <div className="col-md-10">

                        <textarea maxLength="500" value={InsertPostState.RELEATEDPOSTIDS.join(",")}
                            //  onChange={(val) => {
                            //     const updateState = {
                            //         RELEATEDPOSTIDS: val.target.value
                            //     }
                            //     commonSetInsertPostState(updateState);
                            // }}
                            readOnly
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="1"
                            placeholder="Bağlantılı Haberler..."
                        ></textarea>
                    </div>
                    <div className="col-md-2">
                        <Button variant={"info"}
                            className="text-capitalize btn-icon" onClick={() => setReleatedNewsModalVisible(true)}>
                            <span className="ul-btn__text">
                                Haber Ekle
                            </span>
                        </Button>
                    </div>
                </div>
            </div>

            <div className="mt-4"></div>
            <PostImageListModal show={imageLibraryModalVisible} setShow={setImageLibraryModalVisible} onSelectedImage={(imgg) => {
                const updateState = {
                    selectedMainImageType: mainImageType.fromRepository,
                    selectedMainImage: imgg,
                }
                commonSetInsertPostState(updateState);
                setImageLibraryModalVisible(false);
            }}>
            </PostImageListModal>
            <ReleatedPostListModal postId={haberId} show={releatedNewsModalVisible} setShow={setReleatedNewsModalVisible}
                addPostId={addPostId} deletePostId={deletePostId} releatedPostIds={InsertPostState.RELEATEDPOSTIDS}>

            </ReleatedPostListModal>

        </div>
    );

}
export default InsertUpdatePost;




