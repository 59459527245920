import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_USER_DATA: {
      return {
        ...state,
        ...action.data
      };
    }
    case actionTypes.REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case actionTypes.USER_LOGGED_OUT: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
