import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import AppContext from "app/appContext";
import {
  setLayoutSettings,
  setDefaultSettings
} from "app/redux/actions/LayoutActions";

import { GullLayouts } from "."; 

class GullLayout extends Component {
  state = {};
 
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // initCodeViewer();
      // this.updateSettingsFromRouter();
    }
  }
  componentDidMount() {
    this.initAppDirection();
  } 

  initAppDirection = () => {
    let { settings } = this.props;
    setTimeout(() => {
      document.documentElement.setAttribute('dir', settings.dir);
    });
  }

  render() {
    let { activeLayout, route, settings } = this.props;
    let Layout = GullLayouts[activeLayout];
    return (
      <Suspense>
        <Layout routes={route.routes} path = {this.props.location.pathname} ></Layout>
 
      </Suspense>
    );
  }
}

const mapStateToProps = state => ({
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  settings: state.LayoutReducer.settings,
  activeLayout: state.LayoutReducer.settings.activeLayout,
  defaultSettings: state.LayoutReducer.defaultSettings
});

GullLayout.contextType = AppContext;

export default withRouter(
  connect(mapStateToProps, { setLayoutSettings, setDefaultSettings })(
    GullLayout
  )
);
