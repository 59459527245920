import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button, NavItem } from "react-bootstrap";
import globalTypes from "../../helpers/globalTypes";
import network from "../../networking/network";
import checkicon from "../../assets/images/checkicon.png"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import f7WebNetwork from "networking/f7WebNetwork";


const ReleatedPostListModal = ({ show, setShow, releatedPostIds, addPostId, deletePostId, postId }) => {
    const [imageList, setImageList] = useState([]);
    const [selectedImage, setSelectedImage] = useState("");
    const [searchText, setSearchText] = useState("");
    //   const handleClose = () => {
    //     setShow(false);
    //   };
    useEffect(() => {
        getImageFromLibrary();
    }, []);

    useEffect(() => {

        if (show) {
            getImageFromLibrary();
        }

    }, [show]);
    const getImageFromLibrary = async () => {

        if (searchText.length == 0) {

            return;
        }
        var params = {
            searchKey: searchText,
            postId : postId
        }
        var result = await f7WebNetwork.get("/admin/selectPostFromReleatinSearch", params);


        setImageList((result.data || []));
    }

    const getInsertDeleteButton = (row) => {
        const { POSTID } = row;
        var postIdList = releatedPostIds || [];
        var index = postIdList.indexOf(POSTID);

        if (index != -1) {

            return (
                <Button variant="danger" onClick={() => { deletePostId(POSTID) }}> <i className="i-Delete-File"> </i>  Kaldır</Button>
            );
        } else {
            return (
                <Button variant="success" onClick={() => { addPostId(POSTID) }}> <i className="i-Delete-File"> </i>  Ekle</Button>
            );
        }

    }

    const buttonFormatter = (cell, row) => {
        // const rowIndex = commentList.findIndex(w => w.COMMENTID == row2[0]);
        // const row = commentList[rowIndex];
        return getInsertDeleteButton(row);
    }
    const getImageList = () => {

        const paginationOpt = globalTypes.paginationOptions;
        paginationOpt.totalSize = imageList.length;


        return (
            <>
                <div style={{ width: "100%", padding: 15, borderBottomWidth: 0.5, borderBottomStyle: "solid", borderColor: "#000" }}>
                    <div className="row align-items-center font-weight-bold">
                        <div className="col-md-1">
                            Id
                        </div>
                        <div className="col-md-6">
                            Haber Başlığı
                        </div>
                        <div className="col-md-3">
                            Kategori
                        </div>
                        <div className="col-md-2">
                            Actions
                        </div>
                    </div>
                </div>
                {imageList.map((item, index) => (
                    <div style={{ width: "100%", padding: 15, borderBottomWidth: 1, borderBottomStyle: "solid", borderColor: "#ddd" }}>
                        <div className="row align-items-center">
                            <div className="col-md-1">
                                {item.POSTID}
                            </div>
                            <div className="col-md-6">
                                {item.POSTTITLE}
                            </div>
                            <div className="col-md-3">
                                {item.TERMNAME}
                            </div>
                            <div className="col-md-2">
                                {buttonFormatter(null, item)}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
        return (
            <BootstrapTable
                bootstrap4
                keyField="POSTID"
                data={imageList}
                columns={[
                    {
                        dataField: "POSTID",
                        text: "Id",
                        sort: true
                    },
                    {
                        dataField: "POSTTITLE",
                        text: "Haber Başlığı",
                        sort: true
                    },
                    {
                        dataField: "TERMNAME",
                        text: "Kategori",
                        sort: true
                    },
                    {
                        dataField: 'actions',
                        text: 'Actions',
                        isDummyField: true,
                        csvExport: false,
                        formatter: buttonFormatter,
                        headerStyle: {
                            color: 'white'
                        }
                    }
                ]}
                pagination={paginationFactory(paginationOpt)}
                noDataIndication={"Herhangi bir Haber bulunamadı."}

            />
        );
        // return imageList.map((item, i) => {

        //     return (
        //         <div className="col-2" onClick={() => { setSelectedImage(item) }}>
        //             {JSON.stringify(item)}
        //         </div>
        //     );

        // }
        // );
    }

    return (
        <Fragment>

            <Modal show={show} onHide={() => { setShow(false) }} size="xl" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Bağlantılı Haber Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="col-sm-12 row d-flex align-items-center">
                        <div className="col-md-10">
                            <input
                                type="text"
                                className="form-control"
                                id="inputPassword3"
                                placeholder="Haber Ara"
                                value={searchText}
                                onChange={(ev) => {
                                    setSearchText(ev.target.value)
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <Button variant="info" className="btn-icon m-1 text-capitalize"
                                onClick={getImageFromLibrary}
                            >
                                <span className="ul-btn__icon">
                                    <i className="i-Data-Search"></i>
                                </span>
                                <span className="ul-btn__text">Ara</span>
                            </Button>
                        </div>

                    </div>
                    <div className="col-md-12" style={{
                        margin: selectedImage.length > 0 ? 15 : 0
                    }}>
                        <b>
                            {selectedImage}
                        </b>
                    </div>
                    <div className="row">
                        {getImageList()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => { setShow(false) }}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ReleatedPostListModal;




