import React, { useEffect, useRef, useState } from "react"
import globalTypes from "helpers/globalTypes";
import network from "networking/network";
import { Button, Form } from "react-bootstrap";
import localStorageService from "app/services/localStorageService";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import f7WebNetwork from "networking/f7WebNetwork";
import { Alert } from "@mui/material";
import helperFunc from "helpers/helperFunc";


const PostOperations = () => {


    const notificationSendRef = useRef();
    const [userInfo, setUserInfo] = useState({
        UserId: "",
        UserName: "",
        NameSurname: "",
        UserEmail: "",
        UserImage: "",
        UserType: -1
    });

    const [poostList, setPostList] = useState([]);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [pagingOptions, setPagingOptions] = useState({
        start: 0,
        end: 2000
    });

    useEffect(() => {
        setLoadingVisible(true);


        selectUserInfo();


    }, []);

    useEffect(() => {
        selectPostListWithPaging();
    }, [pagingOptions]);

    const selectPostListWithPaging = () => {

        var params = {
            start: pagingOptions.start,
            end: pagingOptions.end
        }
        setLoadingVisible(true);
        f7WebNetwork.get("/admin/selectPostOperations", params)
            .then(result => {
                setLoadingVisible(false);
                var data = result.data || [];
                console.log(data);
                setPostList(data);

            })
    }

    const selectUserInfo = () => {

        var user = helperFunc.getUserInformation();

        setUserInfo(user);

    }


    const getPagingList = () => {

        var list = [];
        for (let i = 0; i < 50; i++) {
            var start = i * 2000;
            var end = start + 2000
            list.push({
                start: start,
                end: end
            });

        }
        return list;
    }



    const paginationOpt = globalTypes.paginationOptions;
    paginationOpt.totalSize = poostList.length;
    paginationOpt.sizePerPage = 30;

    const rangeList = getPagingList();

    return (
        <div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
            }}>

                <div>
                    <label style={{
                        marginRight: 10
                    }}>
                        Gösterim Aralığı:
                    </label>
                    <select onChange={(ev) => {
                        var value = ev.target.value;
                        var rangeList = value.split("_");
                        setPostList([]);
                        setPagingOptions({
                            end: rangeList[1],
                            start: rangeList[0]
                        })
                    }} value={pagingOptions.start + "_" + pagingOptions.end} >
                        <option disabled> Aralık Seçiniz</option>
                        <option value={"0_1500000"}> Tümünü Göster</option>
                        {rangeList.map((item, index) => {

                            return (
                                <option key={"select" + index} value={item.start + "_" + item.end}> {item.start + " - " + item.end + " Arası"}</option>
                            )
                        })}
                    </select>

                </div>
            </div>

            <div>
                {loadingVisible ? (
                    <div>
                        Yükleniyor...
                    </div>
                ) : (
                    <MUIDataTable
                        title={"Haber Listesi"}
                        data={poostList}

                        columns={[
                            {
                                name: "POSTID",
                                label: "Id",
                                sort: true,
                                style: {
                                    width: 50,
                                    columnSpan: 1
                                },

                            },
                            {
                                name: "POSTTITLE",
                                label: "Haber Başlığı",
                                sort: true,
                                search: true,

                                width: 200,
                                style: {
                                    columnSpan: 2,
                                },
                                options: {
                                    customBodyRender: (value) => {

                                        return (
                                            <p style={{
                                                // fontSize:11
                                            }}>
                                                {value}
                                            </p>
                                        );
                                    }
                                }
                            },
                            {
                                name: "OPERATIONDATE",
                                label: "Tarih.",
                                sort: true
                            },
                            {
                                name: "OPERATIONTIME",
                                label: "Saat.",
                                sort: true
                            },
                            {
                                name: "NAMESURNAME",
                                label: "Değiştiren Kullanıcı",
                                sort: true
                            },
                            {
                                name: "OPERATIONTYPE",
                                label: "İşlem Tipi",
                                sort: true,
                                options: {
                                    customBodyRender: (value) => {
                                        if (value == 1) {

                                            return (
                                                <Alert severity={"success"} color="success">
                                                    Ekleme
                                                </Alert>
                                            );
                                        } else if (value == 2) {
                                            return (
                                                <Alert severity={"info"}>
                                                    Güncelleme
                                                </Alert>
                                            );
                                        } else {
                                            return (
                                                <Alert severity={"error"}>
                                                    Pasif/Aktif
                                                </Alert>
                                            );
                                        }

                                    }
                                }
                            },
                        ]}
                        options={{
                            download: false,
                            print: false,
                            viewColumns: false,
                            filterType: "multiselect",
                            isRowSelectable: false,
                            selectableRowsHeader: false,
                            rowsPerPage: 50,
                            selectableRows: "none"
                        }}
                    />
                )}

            </div>

        </div>
    );

}
export default PostOperations;