import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import globalTypes from "../../helpers/globalTypes";
import network from "../../networking/network";
import checkicon from "../../assets/images/checkicon.png"


const PostImageListModal = ({ show, setShow, onSelectedImage }) => {
    const [imageList, setImageList] = useState([]);
    const [selectedImage, setSelectedImage] = useState("");
    const [searchText, setSearchText] = useState("");
    //   const handleClose = () => {
    //     setShow(false);
    //   };
    useEffect(() => {
        getImageFromLibrary();
    }, []);

    useEffect(() => {

        if (show) {
            getImageFromLibrary();
        }

    }, [show]);
    const getImageFromLibrary = async () => {

        var result = await network.get("blogpostadmin/getPostImageRepository");


        setImageList((result.data || []));
    }


    const getPostImage = (item) => {

        var url = globalTypes.serviceUrl + globalTypes.AppServicesAssetsPath.PostImagesPath;

        return url + "" + item;
    }

    const getCheckIcon = (item) => {

        if (item == selectedImage) {

            return (
                <img style={{ width: 36, position: "absolute" }} src={checkicon}></img>
            );
        } else {
            return null;
        }
    }

    const saveSelected = () => {

        if (selectedImage.length > 0) {

            onSelectedImage(selectedImage);

        } else {
            alert("Lütfen bir fotoğraf seçiniz.");
        }

    }


    const getImageList = () => {

        var imagesss = imageList;
        if (searchText.length > 0) {
            imagesss = imagesss.filter(function (el) {
                return el.indexOf(searchText) != -1
            })
        }



        return imagesss.map((item, i) => {

            return (
                <div className="col-2" onClick={() => { setSelectedImage(item) }}>
                    {getCheckIcon(item)}
                    <img style={{ width: 150, height: 150, margin: 10, borderWidth: item == selectedImage ? 5 : 0, borderColor: "green", borderStyle: "solid" }} src={getPostImage(item)}>
                    </img>
                </div>
            );

        }
        );
    }

    return (
        <Fragment>

            <Modal show={show} onHide={() => { setShow(false) }} size="xl" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Kütüphaneden Fotoğraf Seç</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="inputPassword3"
                            placeholder="Fotoğraf Ara"
                            value={searchText}
                            onChange={(ev) => {
                                setSearchText(ev.target.value)
                            }}
                        />
                    </div>
                    <div className="col-md-12" style={{
                        margin: selectedImage.length > 0 ? 15 : 0
                    }}>
                        <b>
                            {selectedImage}
                        </b>
                    </div>
                    <div className="row">
                        {getImageList()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShow(false) }}>
                        İptal
                    </Button>
                    <Button variant="primary" onClick={() => { saveSelected() }}>
                        Fotoğrafı Yükle
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default PostImageListModal;




