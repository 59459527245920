import localStorageService from "app/services/localStorageService";
import globalTypes from "./globalTypes";



const helperFunc = {
    monthToText(month) {

        if (month) {

            switch (parseInt(month)) {
                case 1:

                    return "Oca";
                case 2:

                    return "Şub";
                case 3:

                    return "Mar";
                case 4:

                    return "Nis";
                case 5:

                    return "May";
                case 6:

                    return "Haz";
                case 7:

                    return "Tem";
                case 8:

                    return "Ağu";
                case 9:

                    return "Eyl";
                case 10:

                    return "Eki";
                case 11:

                    return "Kas";
                case 12:

                    return "Ara";

                default:
                    break;
            }
        }
        else return "";

    },

    getBeautyTitle(title) {

        return title.turkishtoEnglish().replace(/ /g, "-").replace(/@/g, "").replace(/\$/g, "").replace(/!/g, "").replace(/#/g, "").toLowerCase();;
    },
    getNewsUrlObj(url, id) {
  
        var urlText = url.toLowerCase().turkishtoEnglish()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'').replace(/ /g, "-").replace(/@/g, "").replace(/\$/g, "").replace(/!/g, "").replace(/#/g, "").toLowerCase();;

        
        return {
            to: "/haber/" + encodeURIComponent(urlText) + "-" + id
        }
    },
    getTagUrl(tag) {
        var urlText = tag.trim();
        return "/tag/" + urlText;
    },
    getNewsImage(imageName) {
        var url = globalTypes.serviceUrl + globalTypes.AppServicesAssetsPath.PostImagesPath + imageName;


        return url;
    },
    getNewsPdfFile(fileName) {
        var url = globalTypes.webProjectUrl + "/documents/newsDocument?fileName=" + fileName;


        return url;
    },
    getAdminImage(imageName) {
        var url = globalTypes.serviceUrl + globalTypes.AppServicesAssetsPath.UserImagesPath + imageName;


        return url;
    },
    getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    },
    getUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    getPostHtmlFromBlockList(htmlBlockList) {

        var content = "";

        for (let i = 0; i < htmlBlockList.length; i++) {
            const element = htmlBlockList[i];

            if (element.blockType == globalTypes.htmlBlockType.textInput) {

                content = content + " " + globalTypes.postSplitTags.textSplitTag + " " + element.blokcHtml
                    + " " + globalTypes.postSplitTags.textSplitTag;

            } else if (element.blockType == globalTypes.htmlBlockType.embedIframe) {
                

                content = content + " " + globalTypes.postSplitTags.embedHtmlSplitTag + " " + element.embedHtml
                + " " + globalTypes.postSplitTags.embedHtmlSplitTag;
                
            } else {
                var image_Url = "";

                if (element.blockImageName && element.blockImageName.length > 0) {

                }
                if (element.blockImageType == globalTypes.mainImageType.fromUrl) {
                    image_Url = element.blockImageName;
                } else if (element.blockImageType == globalTypes.mainImageType.fromBase64) {
                    image_Url = element.blockImageName;
                } else {
                    image_Url = globalTypes.serviceUrl + globalTypes.AppServicesAssetsPath.PostImagesPath + element.blockImageName;
                }
                var bottomText = "";
                if (element.blockImageBottomText && element.blockImageBottomText.length > 0) {
                    bottomText = globalTypes.postSplitTags.imageBottomTextSplitTag + " " + element.blockImageBottomText + " " + globalTypes.postSplitTags.imageBottomTextSplitTag
                }

                content = content + " " + globalTypes.postSplitTags.imageSplitTag + " " + '  <img style="width:100%;" src="' + image_Url + '"></img>'
                    + " " + globalTypes.postSplitTags.imageSplitTag + " " + bottomText;
            }
            content = content + " <br />";
        }
        return content;
    },

    getPostBlockListFromHtml(content) {

        var returnBlock = [];
        var splitList = content.split("<!-- finans");
        splitList = splitList.filter(function (el) {
            return el != null && el.trim().length > 0;
        });

        if (splitList.length == 1) {
            returnBlock.push({
                blockType: globalTypes.htmlBlockType.textInput,
                blokcHtml: splitList[0],
                blockImageType: -1,
                blockImageName: "",
                blockImageFile: "",
                blockImageAlt: "",
                blockImageBottomText: "",
                blockDisplayOrder: 1,
                id: this.getUUID(),
            });

            return returnBlock;
        }
        for (let i = 0; i < splitList.length; i += 2) {
            const element = splitList[i];
            const elementEnd = splitList[i + 1];

            if (elementEnd.trim().indexOf(":paragraph -->") != -1 ) {

                returnBlock.push({
                    blockType: globalTypes.htmlBlockType.textInput,
                    blokcHtml: element.replace(":paragraph -->", ""),
                    blockImageType: -1,
                    blockImageName: "",
                    blockImageFile: "",
                    blockImageAlt: "",
                    blockImageBottomText: "",
                    blockDisplayOrder: i,
                    id: this.getUUID(),
                });

            } else if (elementEnd.trim().indexOf(":img -->") !=-1 ) {

                var srccc = element.replace(":img -->", "");
                var index = srccc.indexOf("src=");
                var res = srccc.substring(index + 5);
                var lastFinish = res.lastIndexOf('"');
                var last = res.substring(0, lastFinish);
                var listtt = last.split("/");
                var imageName = listtt[listtt.length - 1];


                returnBlock.push({
                    blockType: globalTypes.htmlBlockType.imageArea,
                    blokcHtml: "",
                    blockImageType: globalTypes.mainImageType.fromRepository,
                    blockImageName: imageName,
                    blockImageFile: "",
                    blockImageAlt: "",
                    blockImageBottomText: "",
                    blockDisplayOrder: i,
                    id: this.getUUID(),
                });

            } else if (elementEnd.trim().indexOf(":img_bottom -->") != -1) {
                var altText = element.replace(":img_bottom -->", "");
                var blockIndex = returnBlock.findIndex(w=> w.blockDisplayOrder == (i-2));
                returnBlock[blockIndex].blockImageBottomText = altText;
            }
        }
        
        return returnBlock;
    },
    isadminUser (){

        const user = this.getUserInformation();
        var isAdmin = user.isadmin == false ? false : true;
        return isAdmin;
    },
    getUserInformation(){
        const user = localStorageService.getItem("user") ||"{}";

        return JSON.parse(user);
    }
}

export default helperFunc;



String.prototype.replaceAllCharacter = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};
String.prototype.turkishtoEnglish = function () {
    return this.replaceAllCharacter('Ğ', 'g')
        .replaceAllCharacter('Ü', 'u')
        .replaceAllCharacter('Ş', 's')
        .replaceAllCharacter('I', 'i')
        .replaceAllCharacter('İ', 'i')
        .replaceAllCharacter('Ö', 'o')
        .replaceAllCharacter('Ç', 'c')
        .replaceAllCharacter('ğ', 'g')
        .replaceAllCharacter('ü', 'u')
        .replaceAllCharacter('ş', 's')
        .replaceAllCharacter('ı', 'i')
        .replaceAllCharacter('ö', 'o')
        .replaceAllCharacter('ç', 'c');
};