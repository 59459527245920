import React, { useEffect, useRef, useState } from "react"
import { Modal, Button } from "react-bootstrap";
import globalTypes from "../../helpers/globalTypes";
import network from "../../networking/network";
import checkicon from "../../assets/images/checkicon.png"
import { Loading, SimpleCard } from "@gull";
import helperFunc from "helpers/helperFunc";
import Swal from "sweetalert2";
import localStorageService from "app/services/localStorageService";

const ImageRepository = () => {



    const [imageList, setImageList] = useState([]);
    const [selectedImage, setSelectedImage] = useState("");
    const mainImageFileUploadRef = useRef();
    const [mainImageFromFile, setMainImageFromFile] = useState("");
    // const [selectedImageName, setSelectedImageName] = useState("");
    const [searchText, setSearchText] = useState("");
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [loadingVisible, setLoadingVisible] = useState(true);

    const [imageName, setImageName] = useState("");
    //   const handleClose = () => {
    //     setShow(false);
    //   };
    const [userInfo, setUserInfo] = useState({
        UserId: "",
        UserName: "",
        NameSurname: "",
        UserEmail: "",
        UserImage: "",
        UserType: -1
    });
    useEffect(() => {
        getImageFromLibrary();

        var user = helperFunc.getUserInformation();

        setUserInfo(user);

    }, []);
    const getImageFromLibrary = async () => {

        setLoadingVisible(true);
        var result = await network.get("blogpostadmin/getPostImageRepository");

        setImageList(result.data);
        setLoadingVisible(false);
    }
    const addNewImage = () => {

        mainImageFileUploadRef.current.click()
    }

    const onFileUpload = (event) => {

        var file_upload = event.target.files[0];
        setMainImageFromFile(event.target.files[0]);

        var imageName = file_upload.name.split(".")[0];
        setImageName(imageName);
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "myFile",
            file_upload,
            file_upload.name
        );
        setIsFileUpload(true);

    };
    const clearUploadedImage = () => {

        setIsFileUpload(false);
        setMainImageFromFile(null);
    }
    const fileToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const uploadImageConfirm = async () => {

        if (imageName.trim().length == 0) {
            alert("Fotoğraf adı boş olamaz...");
            return;
        }
        var conotrl = imageNameIsAlreadyInsert();
        if (conotrl) {
            alert("Bu Fotoğrafla aynı adda bir fotoğraf bulunmaktadır...");
            return;
        }
        setLoadingVisible(true);
        var base64 = await fileToBase64(mainImageFromFile);



        var imageObj = {
            base64String: base64,
            imageName: helperFunc.getBeautyTitle(imageName) + ".png",
            listIndex: 0,
            isUrlImage: false
        };
        var result = await network.post("blogpostadmin/saveSinglePostImage", imageObj);
        if (result.data == true) {
            getImageFromLibrary();

            Swal.fire({
                title: "Başarılı",
                confirmButtonText: "Ok",
                position: "top-end",
                icon: "success",
                text: "Fotoğraf başarılı bir şekilde eklendi..",
            });
            clearUploadedImage();
        } else {

            setLoadingVisible(false);
            alert(result.errorMessage);
        }


    }

    const deleteImageFromRepository = async () => {

        if (window.confirm("Fotoğraf kalıcı olarak silinecek. Devam edilsin mi?")) {
            setLoadingVisible(true);
            var imageObj = {
                base64String: "",
                imageName: selectedImage,
                listIndex: 0,
                isUrlImage: false,
                userId: userInfo.UserId
            };
            var result = await network.post("blogpostadmin/deleteSinglePostImage", imageObj);
            if (result.data == true) {
                getImageFromLibrary();

                Swal.fire({
                    title: "Başarılı",
                    confirmButtonText: "Ok",
                    position: "top-end",
                    icon: "success",
                    text: "Fotoğraf başarılı bir şekilde eklendi..",
                });
                setSelectedImage("");
            } else {
                setLoadingVisible(false);
                alert(result.errorMessage);
            }
        }

    }

    const getPostImage = (item) => {

        var url = globalTypes.serviceUrl + globalTypes.AppServicesAssetsPath.PostImagesPath;

        return url + "" + item;
    }

    const getCheckIcon = (item) => {

        if (item == selectedImage) {

            return (
                <img style={{ width: 36, position: "absolute" }} src={checkicon}></img>
            );
        } else {
            return null;
        }
    }


    const imageNameIsAlreadyInsert = () => {

        var name = helperFunc.getBeautyTitle(imageName);
        var index = imageList.indexOf(name);


        return index != -1;
    }




    const getDeleteButton = () => {


        if (selectedImage.length > 0) {

            return (


                <Button

                    onClick={() => { deleteImageFromRepository() }}
                    variant="danger"
                    className="btn-icon m-1 text-capitalize"
                >
                    <span className="ul-btn__icon">
                        <i className="i-Close-Window"></i>
                    </span>
                    <span className="ul-btn__text">Seçileni Sistemden Sil</span>

                </Button>

            );
        }


    }
    const getUploadFileButton = () => {

        if (isFileUpload) {


            return (
                <>
                    <Button
                        style={{ marginLeft: "15px !important" }}
                        onClick={() => { clearUploadedImage() }}
                        variant="info"
                        className="btn-icon m-1 text-capitalize"
                    >
                        <span className="ul-btn__icon">
                            <i className="i-Close-Window"></i>
                        </span>
                        <span className="ul-btn__text">Temizle</span>

                    </Button>

                    <Button
                        style={{ marginLeft: "15px !important" }}
                        onClick={() => { uploadImageConfirm() }}
                        variant="info"
                        className="btn-icon m-1 text-capitalize"
                    >
                        <span className="ul-btn__icon">
                            <i className="i-Upload"></i>
                        </span>
                        <span className="ul-btn__text">Sisteme Yükle</span>

                    </Button>

                    <SimpleCard title="Fotoğraf Adı">
                        <div className="form-group">
                            <input maxLength="500" type="text" className="form-control" placeholder="Fotoğraf Adını Giriniz..."
                                value={imageName} onChange={(val) => { setImageName(val.target.value) }}
                            >

                            </input>
                        </div>
                    </SimpleCard>
                </>
            );
        } else {

        }
    }

    const getLoadingPanel = () => {

        if (loadingVisible) {

            return (
                <Loading ></Loading>
            );
        } else {
            return null;
        }
    }

    const getImageList = () => {

        var imagesss = imageList;
        if (searchText.length > 0) {
            imagesss = imagesss.filter(function (el) {
                return el.indexOf(searchText) != -1
            })
        }



        return imagesss.map((item, i) => {

            return (
                <div key={"image" + i} className="col-md-2 col-sm-12" onClick={() => {
                    if (selectedImage == item) {
                        setSelectedImage("")
                    } else
                        setSelectedImage(item)

                }}>
                    {getCheckIcon(item)}
                    <img style={{ width: "100%", height: 200, margin: 10, borderWidth: item == selectedImage ? 5 : 0, borderColor: "green", borderStyle: "solid" }} src={getPostImage(item)}>

                    </img>


                </div>
            );

        }
        );
    }

    return (

        <div className="">

            {getLoadingPanel()}

            <div className="col-sm-10">
                <input
                    type="text"
                    className="form-control"
                    id="inputPassword3"
                    placeholder="Fotoğraf Ara"
                    value={searchText}
                    onChange={(ev) => {
                        setSearchText(ev.target.value)
                    }}
                />
            </div>

            <SimpleCard title="Fotoğraf Listesi">
                <Button variant="primary" onClick={() => { addNewImage() }}>
                    Yeni Fotoğraf Ekle
                </Button>

                {getUploadFileButton()}

                {getDeleteButton()}



                <input
                    ref={mainImageFileUploadRef}
                    style={{ visibility: "hidden" }}
                    type="file"
                    accept="image/*"
                    className="custom-file-input"
                    id="inputGroupFile01"
                    aria-describedby="inputGroupFileAddon01"
                    onChange={onFileUpload}

                />

                <div className="col-md-12">
                    <b>
                        {selectedImage}
                    </b>
                </div>
                <div className="row">
                    {getImageList()}
                </div>
            </SimpleCard>

        </div>
    );
}
export default ImageRepository;