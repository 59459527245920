import React, { useEffect, useState } from "react"


const CategoryListSelectBox = ({ categoryList, selectedCategory, onSelectCategory }) => {

    const [configuredCategoryList, setConfiguredCategoryList] = useState([]);
    //PARENTID
    useEffect(() => {
        if (categoryList && categoryList.length > 0) {
            configureCategoryList()
        }

    }, [categoryList]);

    const configureCategoryList = () => {

        var newList = [];

        var withoutParentsCategories = categoryList.filter(function (el) {
            return el.PARENTID == -1
        });
        var withParentsCategories = categoryList.filter(function (el) {
            return el.PARENTID !== -1
        });
        for (let i = 0; i < withoutParentsCategories.length; i++) {
            const element = withoutParentsCategories[i];

            newList.push({
                ...element,
                subList: []
            });
        }
        for (let i = 0; i < withParentsCategories.length; i++) {
            const element = withParentsCategories[i];
            var parentIdIndex = newList.findIndex(w => w.TERMID == element.PARENTID);
            if (parentIdIndex != -1) {
                newList[parentIdIndex].subList.push(element);
            } 
        }

        console.log(newList);

        setConfiguredCategoryList(newList);
    }
/*

DISPLAYORDER: 1
ICON: ""
ISMAINCATEGORY: true
NAME: "Altın"
PARENTID: -1
SLUG: "altin"
TERMID: 1
subList: []
*/

    const getCategoryItem = () => {

        return configuredCategoryList.map((item, index) => {

            if (item.subList && item.subList.length > 0) {

                return (
                    <optgroup key={"item.subList" + index} label={item.NAME}>
                        {item.TERMID == selectedCategory ? (
                            <option key={"sublist" + 1} selected value={item.TERMID}>{item.NAME}</option>
                        ) : (
                            <option key={"sublist" + 1} value={item.TERMID}>{item.NAME}</option>
                        )}
                        {item.subList.map((item, index) => (
                            item.TERMID == selectedCategory ? (
                                <option key={"sublist" + index} selected value={item.TERMID}>{item.NAME}</option>
                            ) : (
                                <option key={"sublist" + index} value={item.TERMID}>{item.NAME}</option>
                            )
                        ))}
                    </optgroup>
                );
            } else
                if (item.id == selectedCategory) {

                    return (
                        <option key={"selectedCategory" + index}  selected value={item.TERMID}>{item.NAME}</option>
                    );
                } else {
                    return (
                        <option key={"item.id" + index} value={item.TERMID}>{item.NAME}</option>
                    );
                }
        }
        );
    }

    return (
        <select className="form-control " id="sel1" onChange={(ev) => {

            onSelectCategory(ev.target.value);
        }}>
            <option selected disabled>Kategori Seç</option>
            {getCategoryItem()}
        </select>
    );
}
export default CategoryListSelectBox;